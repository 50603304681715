import i18n from "../i18n";


const INITIAL_STATE = {
  isAuthUser: false,
  userInfo: {}
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthUser: true,
        userInfo: action.payload,
      };
    case "LANG_LOAD_SUCCESS":
       loadLangData(action.payload) 
        return {
          ...state,
          langInfo: action.payload
        };
    default:
      return state;
  }
};

const loadLangData = (data) => {
    //setLangs(data.data[0].availableLang)
    data?.data[1].forEach((lang) => {
      const langCode = Object.keys(lang)[0]
      const trans = lang[langCode].translation
      i18n.addResourceBundle(langCode, 'translation', trans)
    })
}

export default loginReducer;
