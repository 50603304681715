import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { makeSelectMasterData } from "../../selectors/chartSelector";
import { fetchMasterDetails } from "../../actions/chartActions";

// import Drawer from "@material-ui/core/Drawer";
import "./FilterComponent.css";
import expand_more from "../../assets/icons/Button-Filter-PointingDown-01.svg";
import year_issue from "../../assets/icons/Icon-Calendar-01.svg";
import rating from "../../assets/icons/Icon-Star-01.svg";
import ratedStar from "../../assets/icons/star(2).png";
import unRatedStar from "../../assets/icons/star(2)-1.png";
import ea_sector from "../../assets/icons/Icon-IndustrySector-01.svg";
import sdg_icon from "../../assets/icons/sdg-icon-3.svg";
import esg_icon from "../../assets/icons/esg-icon7.svg";
import standard from "../../assets/icons/Icon-Standards-01.svg";
import ent_risk from "../../assets/icons/Icon-EnterpriseRisk.svg";
import main_process from "../../assets/icons/Icon-MainProcess-01.svg";
import close_button from "../../assets/icons/Button-Delete.png";
import closeIcon from "../../assets/icons/Buttons-Close.svg";

const FilterComponent = ({
  updateFilter,
  showFilteroptions,
  applyKeyDimension,

  keyDimensionValue,
  closeFilterPanel,
  fetchMasterDetailsData,
  masterData,
}) => {
  const { t, i18n } = useTranslation()
  const [openyearOfIssue, setopenyearOfIssue] = useState(false);
  const [openstarRating, setopenstarRating] = useState(false);
  const [openEASector, setopenEASector] = useState(false);
  //const [openABCSector, setopenABCSector] = useState(false);
  //const [openXYZSector, setopenXYZSector] = useState(false);
  const [openSDG, setopenSDG] = useState(false);
  const [openESG, setopenESG] = useState(false);
  const [openstandard, setopenStandard] = useState(false);
  const [openEntRisk, setopenEntRisk] = useState(false);
  const [openmainProcess, setopenmainProcess] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [appliedFilterItems, setAppliedFilterItems] = useState({});
  const [appliedFilterCounter, setAppliedFilterCounter] = useState(0);
  const [toggleAppliedFilter, setToggleAppliedFilter] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [standradOptions, setStandradOptions] = useState([]);
  const [EAOptions, setEAOptions] = useState([]);
  //const [ABCOptions, setABCOptions] = useState([]);
  //const [XYZOptions, setXYZOptions] = useState([]);
  const [SDGOptions, setSDGOptions] = useState([]);
  const [ESGOptions, setESGOptions] = useState([]);
  const [enterpriseRiskOptions, setEnterpriseRiskOptions] = useState([]);
  const [macroprocessOptions, setMacroprocessOptions] = useState([]);
  const [yearOfIssueOptions, setYearOfIssueOptions] = useState([]);
  const [ratingOption, setRatingOptions] = useState([]);

  useEffect(() => {
    if (Object.keys(checkedItems).length) {
      setDisableButton(false);
    }
  }, [checkedItems]);

  const getDropdownData = (data, type) => {
    const newData = [];
    data.map((item, i) => {
      if (item.type === type) {
        if(item.type=="ESG"){
          newData.push({
            label: item.id[3] + (item.subject ? "-" + item.subject : ""),
            value: item.itemValue,
          });}
        else{
        newData.push({
          label: item.itemValue + (item.subject ? "-" + item.subject : ""),
          value: item.itemValue,
        });}
      }
    });

    return newData;
  };

  useEffect(() => {
    if (masterData && masterData.length > 0) {
      masterData = masterData.filter(item => item.lang === i18n.language)
      const standradsData = getDropdownData(masterData, "standard");
      const eaSectorData = getDropdownData(masterData, "eaSector");
      //const abcSectorData = getDropdownData(masterData, "eaSector");
      //const xyzSectorData = getDropdownData(masterData, "eaSector");
      const SDGData = getDropdownData(masterData, "SDG");
      const ESGData = getDropdownData(masterData, "ESG");
      const enterpriseRiskData = getDropdownData(masterData, "enterpriseRisk");
      const macroprocessData = getDropdownData(masterData, "macroprocess");
      const yearOfIssueData = getDropdownData(masterData, "Year_Of_Issue");
      const ratingData = getDropdownData(masterData, "Rating");
      setStandradOptions(standradsData);
      setEAOptions(eaSectorData);
      setSDGOptions(SDGData);
      setESGOptions(ESGData);
      setEnterpriseRiskOptions(enterpriseRiskData);
      setMacroprocessOptions(macroprocessData);
      setYearOfIssueOptions(yearOfIssueData);
      setRatingOptions(ratingData);
    } else {
      fetchMasterDetailsData();
    }
    clearAllFields();
    updateFilter({});
  }, [masterData,i18n.language]);

  const clearAllFields = () => {
    setAppliedFilterCounter(0);
    setCheckedItems({});
    setSelectedItems({});
    setAppliedFilterItems({});
    setToggleAppliedFilter(true);
  };
  const clearAllFilter = () => {
    clearAllFields();
    updateFilter({});
    closeFilterPanel();
  };

  const onPrimaryFiltersSelected = (e) => {
    applyKeyDimension(e);
    clearAllFields();
    closeFilterPanel();
  };

  const handleChange = (event, record) => {
    // updating an object instead of a Map
    
    setSelectedItems({
      ...selectedItems,
      [event.target.value]: event.target.checked,
    });
    if (event.target.checked) {
      setCheckedItems({
        ...checkedItems,
        [event.target.name]: [
          ...(checkedItems[event.target.name] || []),
          record ? record : event.target.value,
        ],
      });
      
    } else {
      const tempArr = checkedItems[event.target.name].filter((item) =>
        item.value
          ? item.value !== event.target.value
          : item !== event.target.value
      );
      setCheckedItems({ ...checkedItems, [event.target.name]: tempArr });
    }
  };

  const closeAppliedFilter = () => {
    setToggleAppliedFilter(!toggleAppliedFilter);
  };
  const applyFilters = () => {
    setDisableButton(true);

    setAppliedFilterItems(checkedItems);
    let count = 0;
    for (const prop in selectedItems) {
      if (selectedItems[prop]) {
        count++;
      }
    }
    setAppliedFilterCounter(count);
    updateFilter(checkedItems);
    closeFilterPanel();
  };
  const removeItem = (fiterCategory, val) => {
    let filteritems = appliedFilterItems;
    filteritems[fiterCategory] = filteritems[fiterCategory].filter(
      (item) => item !== val
    );
    setAppliedFilterItems(filteritems);
    filteritems = checkedItems;
    filteritems[fiterCategory] = filteritems[fiterCategory].filter(
      (item) => item !== val
    );
    setCheckedItems(filteritems);
    setAppliedFilterCounter(appliedFilterCounter - 1);
    const tempCheckedItems = selectedItems;
    tempCheckedItems[val] = false;
    setSelectedItems(tempCheckedItems);
    updateFilter(checkedItems);
  };

  return (
    <div
      className={
        showFilteroptions
          ? "toggle-filter-container filterWrapper"
          : "toggle-filter-container"
      }
    >
      <div className="filter-container-label filters-back-btn">
        <span onClick={closeFilterPanel}>
          <img className="rotate90Img" src={expand_more} alt="chevron" />
          <img className="rotate90Img" src={expand_more} alt="chevron" />
          <span className="fltTle">{t('filters')}</span>
        </span>
        <span>
          <img
            className="closeFiltericon"
            src={closeIcon}
            onClick={closeFilterPanel}
            alt="close icon"
          />
        </span>
      </div>

      <div className="filterSection">
        <div className="key-dimension">
          <div className="filter-container-label">{t('primaryView')}</div>
          <br />
          <div className="key-dimension-options">
            <label className="dimensionLabel">
              <input
                type="radio"
                value="focusarea"
                name="keyDimension"
                checked={keyDimensionValue === "focusarea"}
                onChange={onPrimaryFiltersSelected}
                className="dimensionCheckBox"
              />{" "}
              <div className="color-box er"></div>
              {t('risks')}
            </label>
            <label className="dimensionLabel">
              <input
                type="radio"
                value="standards"
                name="keyDimension"
                checked={keyDimensionValue === "standards"}
                onChange={onPrimaryFiltersSelected}
                className="dimensionCheckBox"
              />{" "}
              <div className="color-box std"></div>
              {t('standards')}
            </label>
          </div>
          <br />
        </div>
        {appliedFilterCounter > 0 && (
          <div className="applied-filters">
            <div className="filter-item">
              <div className="applied-filter-label">
                {appliedFilterCounter} {t('Applied filters')}
              </div>
              <img
                className={
                  toggleAppliedFilter
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
                onClick={closeAppliedFilter}
              />
            </div>
            <div
              className="display-applied-filters"
              style={{ display: toggleAppliedFilter ? "block" : "none" }}
            >
              {appliedFilterItems &&
                appliedFilterItems.year &&
                appliedFilterItems.year.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('Year of issue')} ({appliedFilterItems.year.length})
                    </div>
                    {appliedFilterItems.year.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d + id}
                          onClick={() => removeItem("year", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d}
                        </div>
                      );
                    })}
                  </div>
                )}
              {appliedFilterItems &&
                appliedFilterItems.rating &&
                appliedFilterItems.rating.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('rating')} ({appliedFilterItems.rating.length})
                    </div>

                    {appliedFilterItems.rating.includes("3") && (
                      <div
                        className="applied-filter-option-item"
                        // key={d + id}
                        onClick={() => removeItem("rating", "3")}
                      >
                        <img
                          className="filter-close-icon"
                          src={close_button}
                          alt="close"
                        />
                        &nbsp;
                        <img className="star-icon" src={ratedStar} alt="star" />
                        <img className="star-icon" src={ratedStar} alt="star" />
                        <img className="star-icon" src={ratedStar} alt="star" />
                        &nbsp;
                        <span>{t('3star')}</span>
                      </div>
                    )}
                    {appliedFilterItems.rating.includes("2") && (
                      <div
                        className="applied-filter-option-item"
                        // key={d + id}
                        onClick={() => removeItem("rating", "2")}
                      >
                        <img
                          className="filter-close-icon"
                          src={close_button}
                          alt="close"
                        />
                        &nbsp;
                        <img className="star-icon" src={ratedStar} alt="star" />
                        <img className="star-icon" src={ratedStar} alt="star" />
                        <img
                          className="star-icon"
                          src={unRatedStar}
                          alt="star"
                        />
                        &nbsp;
                        <span>{t('2star')}</span>
                      </div>
                    )}
                    {appliedFilterItems.rating.includes("1") && (
                      <div
                        className="applied-filter-option-item"
                        // key={d + id}
                        onClick={() => removeItem("rating", "1")}
                      >
                        <img
                          className="filter-close-icon"
                          src={close_button}
                          alt="close"
                        />
                        &nbsp;
                        <img className="star-icon" src={ratedStar} alt="star" />
                        <img
                          className="star-icon"
                          src={unRatedStar}
                          alt="star"
                        />
                        <img
                          className="star-icon"
                          src={unRatedStar}
                          alt="star"
                        />
                        &nbsp;
                        <span>{t('1star')}</span>
                      </div>
                    )}
                  </div>
                )}
              {appliedFilterItems &&
                appliedFilterItems.SDG &&
                appliedFilterItems.SDG.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('sdgs_place')} ({appliedFilterItems.SDG.length})
                    </div>
                    {appliedFilterItems.SDG.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("SDG", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )}  
              {appliedFilterItems &&
                appliedFilterItems.ESG &&
                appliedFilterItems.ESG.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('esg')} ({appliedFilterItems.ESG.length})
                    </div>
                    {appliedFilterItems.ESG.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("ESG", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )}  
              {appliedFilterItems &&
                appliedFilterItems.eaSector &&
                appliedFilterItems.eaSector.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('Industry sector')} ({appliedFilterItems.eaSector.length})
                    </div>
                    {appliedFilterItems.eaSector.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("eaSector", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              {/* {appliedFilterItems &&
                appliedFilterItems.abcSector &&
                appliedFilterItems.abcSector.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      ABC sector ({appliedFilterItems.abcSector.length})
                    </div>
                    {appliedFilterItems.abcSector.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("abcSector", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )} */}
              {appliedFilterItems &&
                appliedFilterItems.standard &&
                appliedFilterItems.standard.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('Standard')} ({appliedFilterItems.standard.length})
                    </div>
                    {appliedFilterItems.standard.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("standard", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              {appliedFilterItems &&
                appliedFilterItems.entRisk &&
                appliedFilterItems.entRisk.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('enterpriseRisk')} ({appliedFilterItems.entRisk.length})
                    </div>
                    {appliedFilterItems.entRisk.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("entRisk", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              {appliedFilterItems &&
                appliedFilterItems.mainProcess &&
                appliedFilterItems.mainProcess.length > 0 && (
                  <div className="filteredItems">
                    <div className="applied-filter-options-label">
                      {t('mainProcess')} ({appliedFilterItems.mainProcess.length})
                    </div>
                    {appliedFilterItems.mainProcess.map((d, id) => {
                      return (
                        <div
                          className="applied-filter-option-item"
                          key={d.value + id}
                          onClick={() => removeItem("mainProcess", d)}
                        >
                          <img
                            className="filter-close-icon"
                            src={close_button}
                            alt="close"
                          />
                          {d.label}
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
            <div className="clearAll" onClick={clearAllFilter}>
              {t('Clear all')}
            </div>
            <hr />
          </div>
        )}

        <div className="filter-by">
          <div className="filter-container-label">{t('selectFilter')}</div>
          <br />
          <div className="filter-conatiner-filters">
            <div
              className="filter-item"
              onClick={() => setopenyearOfIssue(!openyearOfIssue)}
            >
              <img className="filter_icon" src={year_issue} alt="icon" />
              <div className="filter-label"> &nbsp;{t('yearIssue')}</div>
              <img
                className={
                  openyearOfIssue
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openyearOfIssue &&
              yearOfIssueOptions &&
              yearOfIssueOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"year"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={handleChange}
                        id={d + " checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
            <div
              className="filter-item"
              onClick={() => setopenstarRating(!openstarRating)}
            >
              <img className="filter_icon" src={rating} alt="icon" />
              <div className="filter-label"> &nbsp;{t('rating')}</div>
              <img
                className={
                  openstarRating
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openstarRating && (
              <div>
                <div className="filter-option-item">
                  <label>
                    <input
                      type="checkbox"
                      name={"rating"}
                      value={"3"}
                      checked={selectedItems["3"]}
                      onChange={handleChange}
                      id={"3checkbox"}
                    />
                    &nbsp;
                    <img
                      className="star-icon"
                      src={ratedStar}
                      alt="ratedStar"
                    />
                    <img
                      className="star-icon"
                      src={ratedStar}
                      alt="ratedStar"
                    />
                    <img
                      className="star-icon"
                      src={ratedStar}
                      alt="ratedStar"
                    />
                    &nbsp;<span>{t('3star')}</span>
                  </label>
                </div>
                <div className="filter-option-item">
                  <label>
                    <input
                      type="checkbox"
                      name={"rating"}
                      value={"2"}
                      checked={selectedItems["2"]}
                      onChange={handleChange}
                      id={"2checkbox"}
                    />
                    &nbsp;
                    <img
                      className="star-icon"
                      src={ratedStar}
                      alt="ratedStar"
                    />
                    <img
                      className="star-icon"
                      src={ratedStar}
                      alt="ratedStar"
                    />
                    <img
                      className="star-icon"
                      src={unRatedStar}
                      alt="unRatedStar"
                    />
                    &nbsp;<span>{t('2star')}</span>
                  </label>
                </div>
                <div className="filter-option-item">
                  <label>
                    <input
                      type="checkbox"
                      name={"rating"}
                      value={"1"}
                      checked={selectedItems["1"]}
                      onChange={handleChange}
                      id={"1checkbox"}
                    />
                    &nbsp;
                    <img
                      className="star-icon"
                      src={ratedStar}
                      alt="ratedStar"
                    />
                    <img
                      className="star-icon"
                      src={unRatedStar}
                      alt="unRatedStar"
                    />
                    <img
                      className="star-icon"
                      src={unRatedStar}
                      alt="unRatedStar"
                    />
                    &nbsp;<span>{t('1star')}</span>
                  </label>
                </div>
              </div>
            )}
            <div className="blink_me" style={{color:'gold',textAlign:'right',paddingTop:'5px'}}>{t('new')}</div>
            <div
              className="filter-item" style={{paddingTop:'0px'}}
              onClick={() => setopenSDG(!openSDG) }
            >
              
              <img className="filter_icon" src={sdg_icon} alt="icon" />
              <div className="filter-label"> &nbsp;{t('sdgs')}</div>
              <img
                className={
                  openSDG
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openSDG &&
              SDGOptions &&
              SDGOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"SDG"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
              <div className="blink_me" style={{color:'gold',textAlign:'right',paddingTop:'5px'}}>{t('new')}</div>
            <div
              className="filter-item" style={{paddingTop:'0px'}}
              onClick={() => setopenESG(!openESG)}
            >
              <img className="filter_icon" src={esg_icon} alt="icon" />
              <div className="filter-label"> &nbsp;{t('esg')}</div>
              <img
                className={
                  openESG
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openESG &&
              ESGOptions &&
              ESGOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"ESG"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
            <div
              className="filter-item"
              onClick={() => setopenEASector(!openEASector)}
            >
              <img className="filter_icon" src={ea_sector} alt="icon" />
              <div className="filter-label"> &nbsp;{t('eais')}</div>
              <img
                className={
                  openEASector
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openEASector &&
              EAOptions &&
              EAOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"eaSector"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
            {/* <div
              className="filter-item"
              onClick={() => setopenABCSector(!openABCSector)}
            >
              <img className="filter_icon" src={ea_sector} alt="icon" />
              <div className="filter-label"> &nbsp;ABC sector</div>
              <img
                className={
                  openEASector
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openABCSector &&
              ABCOptions &&
              ABCOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"abcSector"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
            <div
              className="filter-item"
              onClick={() => setopenXYZSector(!openXYZSector)}
            >
              <img className="filter_icon" src={ea_sector} alt="icon" />
              <div className="filter-label"> &nbsp;XYZ sector</div>
              <img
                className={
                  openEASector
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openXYZSector &&
              XYZOptions &&
              XYZOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"xyzSector"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })} */}
            <div
              className="filter-item"
              onClick={() => setopenStandard(!openstandard)}
            >
              <img className="filter_icon" src={standard} alt="icon" />
              <div className="filter-label"> &nbsp;{t('Standard')}</div>
              <div className="color-box std"></div>
              <img
                className={
                  openstandard
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openstandard &&
              standradOptions &&
              standradOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"standard"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
            <div
              className="filter-item"
              onClick={() => setopenEntRisk(!openEntRisk)}
            >
              <img className="filter_icon" src={ent_risk} alt="icon" />
              <div className="filter-label"> &nbsp;{t('enterpriseRisk')}</div>
              <div className="color-box er"></div>
              <img
                className={
                  openEntRisk
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openEntRisk &&
              enterpriseRiskOptions &&
              enterpriseRiskOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"entRisk"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d.value + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
            <div
              className="filter-item"
              onClick={() => setopenmainProcess(!openmainProcess)}
            >
              <img className="filter_icon" src={main_process} alt="icon" />
              <div className="filter-label"> &nbsp;{t('mainProcess')}</div>
              <div className="color-box mp"></div>
              <img
                className={
                  openmainProcess
                    ? "filter_dwn_chevron  rotateImg"
                    : "filter_dwn_chevron "
                }
                src={expand_more}
                alt="chevron"
              />
            </div>
            {openmainProcess &&
              macroprocessOptions &&
              macroprocessOptions.map((d, id) => {
                return (
                  <div className="filter-option-item" key={d.value + id}>
                    <label>
                      <input
                        type="checkbox"
                        name={"mainProcess"}
                        value={d.value}
                        checked={selectedItems[d.value]}
                        onChange={(e) => handleChange(e, d)}
                        id={d + "checkbox"}
                      />
                      {d.label}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="apply-filter-button">
        <button
          className="apply-button"
          type="button"
          onClick={applyFilters}
          disabled={disableButton}
        >
          {t('applyFilter')}
        </button>
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    masterData: makeSelectMasterData(state),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    fetchMasterDetailsData: () => dispatch(fetchMasterDetails()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
