import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Rating from "@material-ui/lab/Rating";
import EllipsisTooltip from "react-ellipsis-tooltip";
import ratedStar from "../assets/icons/star(2).png";
import unRatedStar from "../assets/icons/star(2)-1.png";
import logoIcon from "../assets/DNV_logo_RGB.png";
import "./FocusAreaModal.css";

const focusAreaDetails = (props) => {
  var FADetails = props?.viewFocusAreaDetails || [];
  // commenting as we are getting it as array from backend itself
  // const AuditTrialArr = [
  //   "FA_Audit_Trail_PLAN",
  //   "FA_Audit_Trail_DO",
  //   "FA_Audit_Trail_CHECK",
  //   "FA_Audit_Trail_ACT",
  // ];
  // console.log("FADEatils" + JSON.stringify(FADetails));
  // AuditTrialArr.forEach(function (trial) {
  //   var auditTrialArr = FADetails[trial];
  //   auditTrialArr.forEach(function(item,index) {
  //     // Checking if the first character is a bullet and removing it
  //     var firstChar = item.charAt(0);
  //     if(firstChar === '•') {
  //       item = item.substring(1);
  //       auditTrialArr[index] = item;
  //     }
  //     FADetails[trial] = auditTrialArr;
  //   })
  // });
  // console.log('selected focus area final'+ JSON.stringify(FADetails));
  return FADetails;
};
class FocusArea extends Component {
  getRatingStarts = (value) => {
    const ratingArr = [];
    var rated = false;
    for (var i = 0; i < 3; i++) {
      i < value ? (rated = true) : (rated = false);
      ratingArr.push(
        <img
          className="star-icon"
          src={rated ? ratedStar : unRatedStar}
          alt="ratedStar"
          key={i}
        />
      );
    }

    return ratingArr;
  };

  
  render() {
    const { t } = this.props;
    const FADetails = focusAreaDetails(this.props);
    return (
      <div className={!this.props.removeTooltip? "popupStyle": "popupStyleBG"} id={this.props.id || "test"}>
        
        {/* <img
      className="close-icon"
      src={buttonClose}
      onClick={props.closeSlider}
    />
    <img
      className="download-icon"
      src={expand}
      onClick={expandView}
    /> */}
        {FADetails && (
          <div>
            <div className="focus-header">{t('FOCUS AREA')}</div>
          </div>
        )}
        
        <div className="focusArea-content">
          <div>
            {!this.props.removeTooltip && (
            <div className="focus-content">
              
              <div className="dnv-logo">
                <img src={logoIcon} alt="logo" />
              </div>
            </div>
            )}
            {FADetails && (
              <div>
                <div className="focus-sub-header">{FADetails.name}</div>
                
                <div className="focusArea-id">{FADetails.FA_ID}</div>
              </div>
            )}
            {FADetails && (
              <table className="focus-area-modal">
                <tr>
                  <th>{t('yearIssue')}</th>
                  <td>{FADetails.Year_Of_Issue}</td>
                </tr>
                <tr>
                  <th>{t('standards')}</th>
                  <td> 
                  {/* {Array.isArray(FADetails.standard) ? FADetails.standard.join(", ")
                    : FADetails.standard
                  
                  } */}


                      {/* {FADetails.standard.map(function (d) {
                        return (<span>{d.name}</span>);
                      })} */}

                      <span>
                          {
                            this.props.removeTooltip ? (FADetails.standard.map((d, i) =>
                            (<span> {d.name + " - " + d.description}{ FADetails.standard.length - 1 === i ? '' : ','}</span>))) : 
                            (FADetails.standard.length > 1 ? 
                              (<EllipsisTooltip
                                placement="top"
                                tooltip={(FADetails.standard.map((d, i) =>
                                  (" "+d.name + " - " + d.description))) }
                                isMultilineEllipsis
                                lineCount={1}
                                lineHeight={1}
                              >
                                <h1><span>{t('Multiple standards')}</span></h1>
                              </EllipsisTooltip>
                              )
                              
                            : (FADetails.standard[0].name + " - " + FADetails.standard[0].description))
                          }
                      </span>




                      

                    {/* <p>{
                      FADetails.standard.length > 1 ?  "Multiple standards" : FADetails.standard[0].name + " - " + FADetails.standard[0].description

                      } <p/>                  */}
                  </td>
                </tr>
                <tr>
                  <th>{t('EA industry sectors')}</th>
                  <td> 
                      <span>
                          {
                            this.props.removeTooltip ? (FADetails.EA_sector.map((d, i) =>
                            (<span> {d.name + " - " + d.description}{ FADetails.EA_sector.length - 1 === i ? '' : ','}</span>))) : 
                            (FADetails.EA_sector.length > 1 ? 
                              (<EllipsisTooltip
                                placement="top"
                                tooltip={(FADetails.EA_sector.map((d, i) =>
                                  (" "+d.name + " - " + d.description))) }
                                isMultilineEllipsis
                                lineCount={1}
                                lineHeight={1}
                              >
                                <h1><span>{t('Multiple sectors')}</span></h1>
                              </EllipsisTooltip>
                              )
                            : (FADetails.EA_sector[0].name + " - " + FADetails.EA_sector[0].description))

                          }
                      </span>    
                                     
                      {/* {
                      FADetails.EA_sector.length > 1 ?  "Multiple sectors" : FADetails.EA_sector[0].name + " - " + FADetails.EA_sector[0].description

                      } */}
                  </td>
                </tr>
                <tr>
                  <th>{t('sdgs')}</th>
                  <td> 
                      <span>
                          {
                            FADetails.sdg!=''?this.props.removeTooltip ? (FADetails.sdg.map((d, i) =>
                            (<span> {d.name + " - " + d.description}{ FADetails.sdg.length - 1 === i ? '' : ','}</span>))) : 
                            (FADetails.sdg.length > 1 ? 
                              (<EllipsisTooltip
                                placement="top"
                                tooltip={(FADetails.sdg.map((d, i) =>
                                  (" "+d.name + " - " + d.description))) }
                                isMultilineEllipsis
                                lineCount={1}
                                lineHeight={1}
                              >
                                <h1><span>{t('Multiple SDGs (Sust. Dev. Goals)')}</span></h1>
                              </EllipsisTooltip>
                              )
                            : (FADetails.sdg[0].name + " - " + FADetails.sdg[0].description)):'-'

                          }
                      </span>    
                                     
                      {/* {
                      FADetails.EA_sector.length > 1 ?  "Multiple sectors" : FADetails.EA_sector[0].name + " - " + FADetails.EA_sector[0].description

                      } */}
                  </td>
                </tr>
  
                <tr>
                  <th>{t('esg')}</th>
                  <td> 
                      <span>
                          {
                            FADetails.esg!=''?this.props.removeTooltip ? (FADetails.esg.map((d, i) =>
                            (<span> {d.name[0] + " - " + d.description}{ FADetails.esg.length - 1 === i ? '' : ','}</span>))) : 
                            (FADetails.esg.length > 1 ? 
                              (<EllipsisTooltip
                                placement="top"
                                tooltip={(FADetails.esg.map((d, i) =>
                                  (" "+d.name[0] + " - " + d.description))) }
                                isMultilineEllipsis
                                lineCount={1}
                                lineHeight={1}
                              >
                                <h1><span>{t('Multiple ESG Categories')}</span></h1>
                              </EllipsisTooltip>
                              )
                            : (FADetails.esg[0].name[0] + " - " + FADetails.esg[0].description)):'-'

                          }
                      </span>    
                                     
                      {/* {
                      FADetails.EA_sector.length > 1 ?  "Multiple sectors" : FADetails.EA_sector[0].name + " - " + FADetails.EA_sector[0].description

                      } */}
                  </td>
                </tr>
  
                <tr>
                  <th>{t('macroProcess')}</th>
                  <td>{FADetails.Main_process}</td>
                </tr>
                <tr>
                  <th>{t('enterpriseRisk')}</th> 
                  <td>{FADetails.Enterprise_risks}</td>
                </tr>
                  <tr>
                    <th>{t('rating')}</th>
                    <td>
                      <Rating
                        name="half-rating-read"
                        value={
                          this.props.updatedRating || FADetails.averageRating
                        }
                        precision={0.5}
                        readOnly
                        max={3}
                      />
                    </td>
                  </tr>
              </table>
            )}
            <div className="section">
              {FADetails && FADetails.FA_Rationale && (
                <div>
                  <div className="section-header padding-top">{t('rationale')}</div>
                  <div className="content content-padding">
                    {FADetails.FA_Rationale}
                  </div>
                </div>
              )}
            </div>
            <table className="focus-area-audit">
              <tr valign="top">
                <td>
                  <div className="section-header">{t('auditTrailPlan')}</div>
                  {FADetails &&
                    FADetails.FA_Audit_Trail_PLAN &&
                    FADetails.FA_Audit_Trail_PLAN.length > 0 && (
                      <div className="textWrapper">
                        <div className="content">
                          {FADetails.FA_Audit_Trail_PLAN.map(function (d, idx) {
                            return (
                              <ul className="outline-square">
                                <li key={idx}>{d}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </td>
                <td>
                  <div className="section-header">
                    {t('auditTrailDO')}
                  </div>
                  {FADetails &&
                    FADetails.FA_Audit_Trail_DO &&
                    FADetails.FA_Audit_Trail_DO.length > 0 && (
                      <div className="textWrapper">
                        <div className="content">
                          {FADetails.FA_Audit_Trail_DO.map(function (d, idx) {
                            return (
                              <ul className="outline-square">
                                <li key={idx}>{d}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </td>
              </tr>
              <tr valign="top">
                <td>
                  <div className="section-header">{t('auditTrailCheck')}</div>
                  {FADetails &&
                    FADetails.FA_Audit_Trail_CHECK &&
                    FADetails.FA_Audit_Trail_CHECK.length > 0 && (
                      <div className="textWrapper">
                        <div className="content">
                          {FADetails.FA_Audit_Trail_CHECK.map(function (
                            d,
                            idx
                          ) {
                            return (
                              <ul className="outline-square">
                                <li key={idx}>{d}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </td>
                <td>
                  <div className="section-header">{t('auditTrailAct')}</div>
                  {FADetails &&
                    FADetails.FA_Audit_Trail_ACT &&
                    FADetails.FA_Audit_Trail_ACT.length > 0 && (
                      <div className="textWrapper">
                        <div className="content">
                          {FADetails.FA_Audit_Trail_ACT.map(function (d, idx) {
                            return (
                              <ul className="outline-square">
                                <li key={idx}>{d}</li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FocusArea);
