import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";

export default function AddActivity(props) {
  const { t } = useTranslation()
  const [noOfParagraphs, setNoOfParagraphs] = useState({
    ...props.defaultCheckBoxCount,
  });
  const noOfItemsRender = [...Array(noOfParagraphs)];

  useEffect(() => {
    setNoOfParagraphs(props.defaultCheckBoxCount);
  }, [props.defaultCheckBoxCount]);

  const onAddActivity = () => {
    setNoOfParagraphs(noOfParagraphs + 1);
  };
  return (
    <div>
      <div className="audit-container">
        <label
          id="audit-label"
          className={props.newFA ? "required group-heading" : "group-heading"}
        >
          {props.title}
        </label>
        {/* className={props.newFA ? "required group-heading" : "group-heading"} */}
        {/* <Tooltip
          title="Please add atleast one checkpoint"
          placement="right"
          className="tooltip-container"
        >
          <IconButton>
            <InfoTwoToneIcon />
          </IconButton>
        </Tooltip> */}
      </div>
      {noOfItemsRender.map((item, i) => {
        return (
          <div className="textarea-wrapper">
            {/* <input type="checkbox" disabled className="checkbox-cls"></input> */}
            <label class="propose-input">
              <input disabled type="checkbox" />
              <span class="check-box"></span>
            </label>
            <textarea
              type="textarea"
              name={props.name + item}
              className="textarea-cls"
              placeholder={props.placeholder}
              value={props.value[i]}
              onChange={(e) => {
                props.handleChange(e, props.name, i);
              }}
            />
          </div>
        );
      })}
      <div
        className="Add-activity-wrapper"
        className={
          props.readonlyFA ? "Add-activity-hide" : "Add-activity-wrapper"
        }
        onClick={onAddActivity}
      >
        {t('Add checkpoint')}
      </div>
    </div>
  );
}
