import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import withAITracking from "../../withAITracking.jsx";
import { setLoginData } from "../../actions/chartActions";
import "./index.css";
import { reactPlugin } from "../../AppInsights";
import Video from "../../components/Video/index";
import Aside from "../../components/Aside/index";
import Banner from "../../components/Banner/index";

export const Home = (props) => {
  return (
    <div className="homeContainer">
      <div className="main-container">
        <Banner />
      </div>
      <div className="main-container-2">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Aside />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Video />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export function mapDispatchToProps(dispatch) {
  return {
    onSetLoginData: (data) => dispatch(setLoginData(data)),
  };
}

const mapStateToProps = ({ login }) => ({
  isAuthUser: login.isAuthUser || false,
  userInfo: login.userInfo,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(reactPlugin, Home, "Home Page"));
