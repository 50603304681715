import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'font-awesome/css/font-awesome.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faWeixin } from "@fortawesome/free-brands-svg-icons"
import "./index.css";

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedIn from "../../assets/icons/Button-LinkedIn.svg";
// import Facebook from "../../assets/icons/Button-Facebook.svg";
// // import Twitter from "../../assets/icons/Button-Twitter.svg";
// import YouTube from "../../assets/icons/Button-YouTube.svg";

// import LinkedInDark from "../../assets/icons/Button-LinkedIn-Dark.svg";
// import FacebookDark from "../../assets/icons/Button-Facebook-Dark.png";
// // import TwitterDark from "../../assets/icons/Button-Twitter-Dark.svg";
// import YouTubeDark from "../../assets/icons/Button-YouTube-Dark.svg";




export const FooterLinks = (props) => {
    const location = useLocation();
    const value = location.pathname;
    const { t,i18n } = useTranslation()
    const footerIconStyle  = {
      color:  location.pathname === "/focus-area"? '#111D42':'#FFF' ,
      backgroundColor: location.pathname === "/focus-area"? '#FFF':'#009fda' , 
      borderRadius:'50%',
      height:'35px',
      width:'35px',
      paddingTop:'6px'
    };
    return (
      <div className={value === "/focus-area" ? "footer-container-dark" : "footer-container"}>
          {/* <ul class="fa-ul">
  <li><span class="fa-li"><i class="fas fa-check-square"></i></span>List icons can</li>
  <li><span class="fa-li"><i class="fas fa-check-square"></i></span>be used to</li>
  <li><span class="fa-li"><i class="fas fa-spinner fa-pulse"></i></span>replace bullets</li>
  <li><span class="fa-li"><i class="far fa-square"></i></span>in lists</li>
</ul> */}
<section className="footerLinksSectionTop">
<h4 class="socialMediaTitle">{t('FOLLOW US ON SOCIAL MEDIA')}</h4>
<ul className="">
       { i18n.language != "zhs" ? (<>
          <li className="">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://www.linkedin.com/company/dnv"
              title={""}
              rel="noreferrer"
            >
              {/* <img src={value === "/focus-area" ? LinkedInDark:LinkedIn} alt={"LinkedIn"} /> */}
              <LinkedInIcon className="ln-icon"></LinkedInIcon>
            </a>
          </li>
          <li className="">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://twitter.com/DNV_Assurance"
              title={""}
              rel="noreferrer"
            >
              {/* <img src={value === "/focus-area" ? TwitterDark:Twitter} alt={"Twitter"} /> */}
              <TwitterIcon className="twit-icon"></TwitterIcon>
            </a>
          </li>
          <li className="">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://www.facebook.com/DNVAssurance/"
              title={""}
              rel="noreferrer"
            >
              {/* <img src={value === "/focus-area" ? FacebookDark:Facebook} alt={"Facebook"} /> */}
              <FacebookIcon className="fb-icon"></FacebookIcon>
            </a>
          </li>
          <li className="">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://www.youtube.com/user/dnvsoftware"
              title={""}
              rel="noreferrer"
            >
              {/* <img src={value === "/focus-area" ? YouTubeDark:YouTube} alt={"YouTube"} /> */}
              <YouTubeIcon className="ytube-icon"></YouTubeIcon>
            </a>
          </li>
          </>):(
            <li className="foot-icon">
            <a target="_blank"
              className="nav-link pr-0 "
              href="https://mp.weixin.qq.com/s/9wbEBWkd5q-YmoNDpEfcZA"
              title={""}
              rel="noreferrer"
              style={footerIconStyle}
            >
              {/* <img src={value === "/focus-area" ? FacebookDark:Facebook} alt={"Facebook"} /> */}
              <FontAwesomeIcon className="we-icon" icon={faWeixin} />
            </a>
          </li>
          )}
         
        </ul>
</section>
<section className="footerLinksSection">
       <ul className="">
            
            <li className="">
            {/* <FontAwesomeIcon icon={faCoffee} /> */}
            {/* <FontAwesomeIcon icon={faAngleLeft} className="faArrow" size="xs"/> */}
            <ArrowForwardIosIcon className="text"></ArrowForwardIosIcon>
            <a target="_blank"
                className="nav-link pr-0"
                href="https://www.dnv.com/about/business-assurance/index.html"
                title={""}
                rel="noreferrer"
            >
                {t('About DNV')}
            </a>
            </li>
            <li className="">
            <ArrowForwardIosIcon className="text"></ArrowForwardIosIcon>
            <a target="_blank"
                className="nav-link pr-0"
                href="https://www.dnv.com/assurance/contact/boost-my-audit-support.html"
                title={""}
                rel="noreferrer"
            >
                {t('Contact')}
            </a>
            </li>
            <li className="">
            <ArrowForwardIosIcon className="text"></ArrowForwardIosIcon>
            <a target="_blank"
                className="nav-link pr-0"
                href="https://www.dnv.com/assurance/digital-services/boost-my-audit-terms.html"
                title={""}
                rel="noreferrer"
            >
                {t('Terms of use')}
            </a>
            </li>
            <li className="">
            <ArrowForwardIosIcon className="text"></ArrowForwardIosIcon>
            <a target="_blank"
                className="nav-link pr-0"
                href="https://www.dnv.com/terms/index.html"
                title={""}
                rel="noreferrer"
            >
                {t('General terms')}
            </a>
            </li>
            <li className="">
            <ArrowForwardIosIcon className="text"></ArrowForwardIosIcon>
            <a target="_blank"
                className="nav-link pr-0"
                href="https://www.dnv.com/privacy/index.html"
                title={""}
                rel="noreferrer"
            >
                {t('Privacy statement')}
            </a>
            </li>
            <li className="">
            <ArrowForwardIosIcon className="text"></ArrowForwardIosIcon>
            <a target="_blank"
                className="nav-link pr-0"
                href="https://www.dnv.com/copyright/index.html"
                title={""}
                rel="noreferrer"
            >
                {isDesktop ? t('Copyright') : t('Copyright') + " © DNV AS 2021"}
            </a>
            </li>
        </ul>
        </section>
      </div>
    );
};

export default connect(null, null)(FooterLinks);
