import { useEffect, useState } from "react";
import {
  useHistory,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { connect } from "react-redux";
import { isIE, isMobile, isTablet, isDesktop } from 'react-device-detect';
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import NetworkLinks from "./containers/Network/Network";
import Home from "./containers/Home";
import User from "./containers/User";
import Login from "./containers/Login";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AuthRoute from "./AuthRoute";
import Proposals from "./containers/Proposals";
import Admin from "./containers/Admin";
import Unauthorised from "./Error/Unauthorised";
import "./App.css";
import { setLoginData } from "./actions/chartActions";
import "react-toastify/dist/ReactToastify.css";
import { reactPlugin, appInsights } from "./AppInsights";
import FooterLinks from "./components/FooterLinks";
import MaintenancePage from "./containers/Manitenance"
import i18n from "./i18n";


function App(props) {
  const history = useHistory();
  const [langs, setLangs] = useState([]);
  var routePath = history.location.pathname;
  var routeArray = [
    "/focus-area",
    "/proposals",
    "/admin",
    "/home",
    "/unathorize",
  ];

  if (routePath.includes('/getHealth')) {
    return <HealthComponent />
  }
  else if (!props.isAuthUser && routeArray.includes(routePath))  {
    // if (routePath==="/focus-area") {
    //   history.push(routePath);
    // }else{
    history.push("/");
    //}    
  }
  const subscribeToRefreshToken = (loggedInuserData) => {
    const options = {
      method: "POST",
      data: {
        refresh_token: loggedInuserData.refresh_token,
      },
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },
      url: `${process.env.REACT_APP_API_URL}/refreshToken`,
    };
    setInterval(() => {
      axios(options)
        .then((userInfo) => {
          const userInfoData = {
            ...userInfo.data.auth_code,
            ...userInfo.data.userInfo,
          };
          sessionStorage.setItem(
            "access_token",
            userInfo.data.auth_code.access_token
          );
          props.onSetLoginData(userInfoData);
        })
        .catch((error) => {
          appInsights.trackException({
            error: new Error("API error"),
            severityLevel: "high",
          });
        });
    }, 3000000); // 50 minutes
  };
  if (isIE) return (
    <div className="error-container">
      <div className="error-msg">
        The site can be best viewed in Edge or Chrome browsers
      </div>
    </div>
  );

  return (
    <AppInsightsErrorBoundary
      onError={(error) =>
        appInsights.trackException({
          error: new Error("some error"),
          severityLevel: "high",
        })
      }
      appInsights={reactPlugin}
    >
      <div className="App">
        <ToastContainer />
        <Router>
          {props.isAuthUser ? (
            <>
              <Header userInfo={props.userInfo} langs={props.langInfo}></Header>
            </>
          ) : null}

          <Switch>
            <Route exact path="/" component={Login} />
            {/* <Route exact path="/" component={MaintenancePage} /> */}
            <Route exact path="/unathorize" component={Unauthorised} />
            <AuthRoute exact path="/proposals" component={Proposals} />
            <AuthRoute exact path="/admin" component={Admin} />
            <AuthRoute exact path="/focus-area" component={NetworkLinks} />
            <Route
              exact
              path="/user"
              render={(props) => (
                <User
                  {...props}
                  subscribeToRefreshToken={subscribeToRefreshToken}
                />
              )}
            />
            <AuthRoute exact path="/home" component={Home} />
            <AuthRoute exact path="/login" component={Login} />
            {/* <Route path="/process" component={NetworkProcess} /> */}
          </Switch>
          {props.isAuthUser ? (
            <>
              {isDesktop ? (<Footer></Footer>) : (<FooterLinks></FooterLinks>)}
              {/* {routeArray.includes(routePath="/focus-area") && isMobile ? "" : isDesktop ? <Footer></Footer> : isMobile ? <FooterLinks></FooterLinks>: <Footer></Footer>} */}
            </>
          ) : null}
        </Router>
      </div>
    </AppInsightsErrorBoundary>
  );

}
const mapStateToProps = ({ login }) => ({
  isAuthUser: login.isAuthUser || false,
  userInfo: login.userInfo,
  langInfo: login.langInfo,
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetLoginData: (data) => dispatch(setLoginData(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
