import React, { Component } from "react";
import "./index.css";

class Unauthorised extends Component {
  render() {
    return (
      <div className="error-container">
        <span className="error-code">401</span>
        <div className="error-msg">
          You are not an authorized user of this application
        </div>
      </div>
    );
  }
}
export default Unauthorised;
