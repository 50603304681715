import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import 'font-awesome/css/font-awesome.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeixin,faYoutube,faLinkedinIn,faTwitter,faFacebookF } from "@fortawesome/free-brands-svg-icons"

export const Footer = (props) => {
  const location = useLocation();
  const value = location.pathname;
  const footerIconStyle  = {
      color:  location.pathname === "/focus-area"? '#111D42':'#FFF' ,
      backgroundColor: location.pathname === "/focus-area"? '#FFF':'#111D42' , 
      borderRadius:'50%',
      height:'23px',
      width:'23px',
      paddingTop:'1px',
      // paddingLeft:'1px'
    };
  const { t,i18n } = useTranslation()
  return (
    <div className={value === "/focus-area" ? "footer-dark" : "footer"}>
      <div className="float-left">
        <ul className="abtDNV navbar-nav mt-2 mt-lg-0 nav nav-tabs">
          <li
            className={
              value === "/focus-area"
                ? "nav-item tab text-color-dark"
                : "nav-item tab text-color"
            }
          >
          </li>
          <li className="nav-item mr-auto">
            <a target="_blank"
              className="nav-link pr-0"
              href={t('about_link')}
              title={""}
              rel="noreferrer"
            >
              {t('About DNV')}
            </a>
          </li>
          <li className="nav-item mr-auto">
            <a target="_blank"
              className="nav-link pr-0"
              href={t('contact_link')}
              title={""}
              rel="noreferrer"
            >
              {t('Contact')}
            </a>
          </li>
          <li className="nav-item mr-auto">
            <a target="_blank"
              className="nav-link pr-0"
              href={t('assurance_link')}
              title={""}
              rel="noreferrer"
            >
              {t('Terms of use')}
            </a>
          </li>
          <li className="nav-item mr-auto">
            <a target="_blank"
              className="nav-link pr-0"
              href={t('terms_link')}
              title={""}
              rel="noreferrer"
            >
              {t('General terms')}
            </a>
          </li>
          <li className="nav-item mr-auto">
            <a target="_blank"
              className="nav-link pr-0"
              href={t('privacy_link')}
              title={""}
              rel="noreferrer"
            >
              {t('Privacy statement')}
            </a>
          </li>
          <li className="nav-item mr-auto">
            <a target="_blank"
              className="nav-link pr-0"
              href={t('copyright_link')}
              title={""}
              rel="noreferrer"
            >
              {t('Copyright')}
            </a>
          </li>
        </ul>
      </div>
      <div className="float-right">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 nav nav-tabs">
          <li
            className={
              value === "/focus-area"
                ? "nav-item tab text-color-dark"
                : "nav-item tab text-color"
            }
          >
            {t('Copyrightyear')}
          </li>
          {i18n.language != "zhs" ? (<>
            <li 
            className={
              value === "/focus-area"
                ? "nav-item tab text-color-dark"
                : "nav-item tab text-color"
            }
          >|</li>
          <li className="nav-item mr-auto foot-icon">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://www.linkedin.com/company/dnv"
              title={""}
              rel="noreferrer"
              style={footerIconStyle}
              >
               <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </li>
          <li className="nav-item mr-auto foot-icon">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://twitter.com/DNV_Assurance"
              title={""}
              rel="noreferrer"
              style={footerIconStyle}
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li className="nav-item mr-auto foot-icon">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://www.facebook.com/DNVAssurance/"
              title={""}
              rel="noreferrer"
              style={footerIconStyle}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a> 
           </li>
           <li className="nav-item mr-auto foot-icon">
            <a target="_blank"
              className="nav-link pr-0"
              href="https://www.youtube.com/user/dnvsoftware"
              title={""}
              rel="noreferrer"
              style={footerIconStyle}
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
          </>):(<>
            <li className="nav-item mr-auto foot-icon"> 
            <a target="_blank"
              className="nav-link pr-0"
              href="https://mp.weixin.qq.com/s/9wbEBWkd5q-YmoNDpEfcZA"
              title={""}
              rel="noreferrer"
              style={footerIconStyle}
            > 

            <FontAwesomeIcon icon={faWeixin} />
              </a>
          </li>
          </>)}
         
        </ul>
      </div>
    </div>
  );
};
export default connect(null, null)(Footer);
