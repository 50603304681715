import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import "./index.css";

class Aside extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="content-container">
        <div className="content-title-2">
        {t('title-2')}
        </div>
        <div className="content-description-2">
        {t('description-2')}
          <br />{t('homeDesc')}
        </div>
      </div>
    );
  }
}
export default withTranslation()(Aside)
