export default class EntRiskJson {
  data = [];
  constructor(entRiskValue) {
    //entRiskValue.EnterpriseRisks = {};
    (entRiskValue.EnterpriseRisks || [])?.forEach((element) => {
      this.data.push(new EntRiskLevelZeroNode(element));
    });
  }
}
class EntRiskLevelZeroNode {
  name = null;
  color = "#B42126";
  category = "Enterprise Risks";
  level = 0;
  key = 0;
  children = [];
  constructor(element) {
    this.key = element.key;
    this.name = element.name;
    (element.EnterpriseRisksName || []).forEach((entRisk) => {
      this.children.push(new EnterpriseRisk(entRisk));
    });
  }
}
class EnterpriseRisk {
  name = null;
  color = "#B42126";
  category = "Enterprise Risks";
  level = 1;
  children = [];
  key = null;
  description = null;
  constructor(entRisk) {
    this.key = entRisk.key;
    this.name = entRisk.name;
    this.description = entRisk.description;
    (entRisk.Standards.length > 0 ? entRisk.Standards : [])?.forEach(
      (standard) => {
        this.children.push(new Standard(standard, entRisk.name));
      }
    );
  }
}
class Standard {
  name = null;
  color = "#003591";
  level = 2;
  children = [];
  key = null;
  constructor(standard, entRisk) {
    this.name = standard.name;
    this.key = standard.key;
    (standard.FocusAreas || []).forEach((focusArea) => {
      this.children.push(new FocusArea(focusArea, standard.name, entRisk));
    });
  }
}
class FocusArea {
  name = null;
  EA_sector = {};
  sdg = {};
  esg = {};
  Enterprise_risks = null;
  standard = {};
  averageRating = null;
  color = null;
  category = "Focus Area";
  level = 3;
  Year_Of_Issue = null;
  Main_process = null;
  FA_Audit_Trail_ACT = null;
  FA_Audit_Trail_CHECK = null;
  FA_Audit_Trail_DO = null;
  FA_Audit_Trail_PLAN = null;
  FA_Rationale = null;
  key = null;
  FA_ID = null;
  constructor(focusArea, standard, entRisk) {
    this.key = focusArea.key;
    this.name = focusArea.name || "";
    this.color = focusArea.color || "#C1762C";
    this.Year_Of_Issue = focusArea.Year_Of_Issue || "";
    this.averageRating = focusArea.averageRating || "";
    this.FA_Rationale = focusArea.FA_Rationale || "";
    this.EA_sector = focusArea.EA_sector ? focusArea.EA_sector : "";
    this.sdg = focusArea.sdg ? focusArea.sdg : "";
    this.esg = focusArea.esg ? focusArea.esg : "";
    this.Enterprise_risks = focusArea.Enterprise_risks || entRisk;
    this.standard = focusArea.standard ? focusArea.standard : "";
    this.Main_process = focusArea.Main_process;
    this.FA_Audit_Trail_ACT = focusArea.FA_Audit_Trail_ACT;
    this.FA_Audit_Trail_CHECK = focusArea.FA_Audit_Trail_CHECK;
    this.FA_Audit_Trail_DO = focusArea.FA_Audit_Trail_DO;
    this.FA_Audit_Trail_PLAN = focusArea.FA_Audit_Trail_PLAN;
    this.FA_ID = focusArea.FA_ID;
  }
}
