import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Spinner from "../../components/Spinner.jsx";

import { makeSelectChartData } from "../../selectors/chartSelector";
import {
  fetchChartDetails,
  updatePanelShowHideFlag,
} from "../../actions/chartActions";
import Legend from "../../components/Legend/Legend";
import zoomInIcon from "../../assets/icons/Icon-Magnificaiton-Plus.svg";
import expandIcon from "../../assets/icons/Icon-ZoomToFit-DarkBlue.svg";
import filterIcon from "../../assets/icons/filter.svg";
import zoomOutIcon from "../../assets/icons/Icon-Magnificaiton-Minus.svg";
import FilterComponent from "../../components/Filters/FilterComponent";
import SearchBar from "../../components/Search/SearchBar";
import InfoPanel from "../InfoPanel";
import FocusAreaModel from "../../components/FocusAreaModal";
import StandardJson from "../../models/Standard";
import EntRiskJson from "../../models/EnterpriseRisk";
import { appInsights, reactPlugin } from "../../AppInsights";
import withAITracking from "../../withAITracking.jsx";
import "./Network.css";

declare var d3;
// const isMobile = window.innerWidth <= 768;
// const isTablet = window.innerWidth >= 768 && window.innerWidth < 1280;
// const isDesktop = window.innerWidth >= 1280;

export class NetworkLinks extends Component {
  constructor() {
    super();
    this.state = {
      showFilteroptions: false,
      openModal: false,
      keyDimensionValue: null,
      viewFocusAreaDetails: [],
      searchData: {},
      openPanel: false,
      selectedNode: null,
      chartData: {},
      loading: true,
      updatedRoot: {},
      isPanelArrowShow: false,
      lang: 'en'
    };
    this.root = [];
    this.rootCopy = [];
    this.svg = {};
    this.prevselectedNode = null;
    this.prevOpenedlevelTwoNode = null;
    this.zoom = null;
    this.path = null;
    this.updateNode = null;
    this.zoomableCanvas = null;

    this.isZoomInOut = false;
    this.clickedNode = null;
    this.isZoomedNodeMobile = false;
  }

  handleFocusAreaClick = (selectedNodeData) => {
    if (selectedNodeData.FA_ID) {
      appInsights.trackEvent({
        name: "Navigated",
        properties: {
          FATitle: selectedNodeData.name,
          faId: selectedNodeData.FA_ID,
          userId: this.props.userInfo.userId,
        },
      });
    }
    // var event = new MouseEvent("dblclick", d3.event);
    //this.svg[0][0].dispatchEvent(event);
    // if (selectedNodeData.level !== 0) {
    this.setState({
      selectedNode: JSON.parse(JSON.stringify(selectedNodeData)),
    });
    if (isDesktop) {
      this.setState({ openPanel: true });
    } else {
      this.setState({ openPanel: false });
    }
    // }
  };
  toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ open: open });
  };

  /** Function that draws the network chart with the JSON provided */
  drawChart = () => {
    var me = this;
    const ele = document.getElementById("network");

    var width = ele.clientWidth,
      height = ele.clientHeight;
    var active = d3.select(null);
    me.active = active;
    // var width = ele.clientWidth > 800 ? ele.clientWidth : 800,
    // height = ele.clientHeight > 800 ? ele.clientHeight : 800;

    var force = d3.layout
      .force()
      // .linkDistance(height / 3)
      .linkDistance((d) => {
        return d.source.level === 0
          ? height / 3
          : d.source.level === 1
          ? height / 8
          : d.source.level === 2
          ? height / 9
          : d.source.level === 3
          ? height / 10
          : height / 10;
      })
      .charge(-120)
      .gravity(0.05)
      .size([width, height])
      .on("tick", tick)
      //.start()
      .stop();
    //Adding for zooming and dragging of graphs *Start*
    var drag = force.drag().on("dragstart", dragstart);
    this.zoom = d3.behavior.zoom().scaleExtent([0.5, 8]).on("zoom", zoom);
    var svg = d3
      .select("#network")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      //Adding for zooming and dragging of graphs *Start*

      .attr("id", "canvas")
      .call(this.zoom)
      .append("g")
      .attr("id", "zoomableCanvas");
    var linkContainer = svg.append("g").attr("id", "links-container");

    var path = null;

    //svgElement is an actual element such as a rect or text or group
    svg.call(this.zoom);

    this.svg = svg;

    // var tooltipDiv = d3
    //   .select("#network")
    //   .append("div")
    //   .attr("class", "tooltip")
    //   .style("opacity", 0);

    var link = svg.selectAll(".link"),
      node = svg.selectAll(".node");

    // console.log(this.root,this.clonedRoot)

    const toggleNodeAndLink = (d, className) => {
      const sourceLinks = document.getElementsByClassName(className);
      if (d.children != null) {
        d._children = d.children;
        d.children = null;
        for (let i = 0; i < sourceLinks.length; i++) {
          sourceLinks[i].style.display = "none";
        }
        // this.fixZoomScalePosition(-0.5);
      } else {
        d.children = d._children;
        d._children = null;
        for (let i = 0; i < sourceLinks.length; i++) {
          sourceLinks[i].style.display = "block";
        }
        // this.fixZoomScalePosition(0.5);
      }
    };
    // Toggle children on click.
    const click = (selectedNodeData) => {
      if (d3.event.defaultPrevented) return; // ignore drag
      // console.log("cw in click", selectedNodeData, isMobile);
      if (isDesktop) {
        if (selectedNodeData.level !== 0) {
          zoomClickedNodeNew(selectedNodeData);
          me.handleFocusAreaClick(selectedNodeData);
          this.props.onUpdatePanelShowHideFlag(true);
        } else {
          var active = d3.select(".fixed");
          active.classed("fixed", false);
          this.props.onUpdatePanelShowHideFlag(false);
        }
      } else {
        me.clickedNode = selectedNodeData;
        me.isZoomedNodeMobile = true;
        /* console.log(
          "cw in click props.isPanelArrow ",
          this.props,
          this.props.isPanelShow,
          selectedNodeData
        ); */

        if (selectedNodeData.level !== 0) {
          me.handleFocusAreaClick(selectedNodeData);
          this.setState({ isPanelArrowShow: true });
          this.props.onUpdatePanelShowHideFlag(true);
        } else {
          this.setState({ isPanelArrowShow: false });
          this.props.onUpdatePanelShowHideFlag(false);
        }

        //   me.zoomOutNodeForMobile();
       /*  console.log(
          "cw in click props.isPanelArrow ",
          this.props,
          this.props.isPanelShow,
          selectedNodeData
        ); */
      }

      // toggle children
      if (selectedNodeData.level === 2) {
        toggleNodeAndLink(
          selectedNodeData,
          "link source" + selectedNodeData.id
        );

        if (
          this.prevOpenedlevelTwoNode &&
          this.prevOpenedlevelTwoNode.children &&
          this.prevOpenedlevelTwoNode.id !== selectedNodeData.id
        ) {
          toggleNodeAndLink(
            this.prevOpenedlevelTwoNode,
            "link source" + this.prevOpenedlevelTwoNode.id
          );
        }
      }

      if (
        (selectedNodeData.level === 0 || selectedNodeData.level === 1) &&
        this.prevOpenedlevelTwoNode != null
      ) {
        toggleNodeAndLink(
          this.prevOpenedlevelTwoNode,
          "link source" + this.prevOpenedlevelTwoNode.id
        );
        //To Show/Hide previous selected L3 node
        if (
          this.prevOpenedlevelTwoNode.children &&
          this.prevOpenedlevelTwoNode.id !== selectedNodeData.id
        ) {
          toggleNodeAndLink(
            this.prevOpenedlevelTwoNode,
            "link source" + this.prevOpenedlevelTwoNode.id
          );
        }
      }
      //highlight selected node
      if (
        this.prevselectedNode &&
        this.prevselectedNode.id !== selectedNodeData.id
      ) {
        const prevNode = document.getElementById(
          this.prevselectedNode.id + this.prevselectedNode.name + "SelectedNode"
        );
        if (prevNode) {
          prevNode.style.opacity = 0;
        }
        // hide other child nodes except the selected one
        // if (this.prevselectedNode.level >= d.level) {
        //   if (this.prevselectedNode.children) {
        //     this.prevselectedNode._children = this.prevselectedNode.children;
        //     this.prevselectedNode.children = null;
        //   }
        // }
      }
      // if (!this.prevselectedNode || this.prevselectedNode.level >= d.level) {
      this.prevselectedNode = selectedNodeData;
      if (selectedNodeData.level === 2) {
        this.prevOpenedlevelTwoNode = selectedNodeData;
      }
      const currentNode = document.getElementById(
        selectedNodeData.id + selectedNodeData.name + "SelectedNode"
      );
      if (currentNode) {
        currentNode.style.opacity = 1;
      }
      hideRequiredNodes(selectedNodeData);
      update();
    };

    /* On click of node it is zooming on scale 5 and center that node*/
    function zoomClickedNodeNew(d) {
      if (me.active.node()) {
        const activeExist = me.active.node().__data__;
        console.log("cw in zoomClickedNodeNew if", me.active.node().id, d.id);

        if (activeExist.id === d.id) {
          return reset();
        }
        me.active.classed("fixed", false);
      }

      const element = document.getElementById("zoomableCanvas");
      const elementScale = me.getScale(element);
      let clickedScale = 3;
      /*if node is already in zoomed in/out state then using existing scale*/
      if (me.isZoomInOut) {
        clickedScale = elementScale;
      }
      var active = d3.select(".fixed");
      me.active = active;

      /* calculation of scale and width */
      if (
        active.node &&
        active.node() &&
        active.node().getBBox &&
        active.node().getBBox()
      ) {
        var bboxNew = active.node().getBBox();
        bboxNew.x = d.px - d.r;
        bboxNew.y = d.py - d.r;
        var boundsNew = [
          [bboxNew.x, bboxNew.y],
          [bboxNew.x + bboxNew.width, bboxNew.y + bboxNew.height],
        ];

        var dx = boundsNew[1][0] - boundsNew[0][0],
          dy = boundsNew[1][1] - boundsNew[0][1],
          x = (boundsNew[0][0] + boundsNew[1][0]) / 2,
          y = (boundsNew[0][1] + boundsNew[1][1]) / 2,
          scale = Math.max(
            0.6,
            Math.min(clickedScale, 0.6 / Math.max(dx / width, dy / height))
          ),
          translate = [width / 2 - scale * x, height / 2 - scale * y];

        svg
          .transition()
          .duration(750)
          .call(me.zoom.translate(translate).scale(scale).event);
      }
    }

    /*On click of zoomed node reset network chart */
    function reset() {
      var active = d3.select(".fixed");
      active.classed("fixed", false);
      active = d3.select(null);
      me.active = active;
      me.isZoomInOut = false;
      svg
        .transition()
        .duration(750)
        .call(me.zoom.translate([0, 0]).scale(1).event);
    }

    const hideRequiredNodes = (d) => {
      const allLinks = document.getElementsByClassName("link");
      for (var i = 0; i < allLinks.length; i++) {
        allLinks[i].style.opacity = 0.3;
      }
      const allnodes = document.getElementsByClassName("node");
      for (i = 0; i < allnodes.length; i++) {
        allnodes[i].style.opacity = 0.5;
      }
      enableParents({ children: this.root }, d);
    };
    let enableParents = (nodes, d) => {
      (nodes.children || []).forEach((node) => {
        node.isSelectedParent = false;
        enableParents(node, d);
        if (node.id === d.id) {
          node.isSelectedParent = true;
          nodes.isSelectedParent = true;
          enableChilds(node.children || []);
        }
        if (node.isSelectedParent) {
          if (node.level !== 0) {
            const link = document.getElementById("link target" + node.id);
            if (link?.style?.opacity) {
              link.style.opacity = 1;
            }
          }
          const targetnode = document.getElementById(
            node.id + node.name + "node"
          );
          if (targetnode?.style?.opacity) {
            targetnode.style.opacity = 1;
          }
          nodes.isSelectedParent = true;
        }
      });
    };
    let enableChilds = (nodes) => {
      nodes.forEach((node) => {
        if (node.children) {
          enableChilds(node.children);
        }
        // if (node.level != 0) {
        const link = document.getElementById("link target" + node.id);
        if (link) {
          link.style.opacity = 1;
        }
        // }
        const targetnode = document.getElementById(
          node.id + node.name + "node"
        );
        if (targetnode) {
          targetnode.style.opacity = 1;
        }
      });
    };
    const update = () => {
      const { t } = this.props;
      const labelTitleEN = t("enterpriseRisk")
      const labelTitleST = t("Standard")
      var nodes = flatten(this.root),
        links = d3.layout.tree().links(nodes);

      // Restart the force layout.
      force.nodes(nodes).links(links).start();

      // Update links.
      link = link.data(links, function (d) {
        return d.target.id;
      });

      link.exit().remove();
      const handleMouseOut = (d) => {
        const mouselinkEvent = document.getElementById(
          d.target.id + d.target.name + "link"
        );
        // if (
        //   mouselinkEvent.style.opacity.length === 0 ||
        //   mouselinkEvent.style.opacity === "1"
        // ) {
        //   this.onCheckBoxValueChange();
        // }
      };
      const nodeMouseOut = (d) => {
        // tooltipDiv.transition().duration(500).style("opacity", 0);
        const sourceNode = document.getElementById(d.id + d.name + "node");
        if (
          sourceNode.style.opacity.length === 0 ||
          sourceNode.style.opacity === "1"
        ) {
          this.onCheckBoxValueChange();
        }
      };

      // // Transition links to their new position.
      // link.transition()
      //   .duration(750)
      //   .attr("d", diagonal);

      // // Transition exiting nodes to the parent's new position.
      // link.exit().transition()
      //   .duration(750)
      //   .attr("d", function(d) {
      //   var o = {x: d.x, y: d.y};
      //   return diagonal({source: d.source, target: d.target});
      //   })
      //   .remove();

      // Stash the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
      // link
      //   .enter()
      //   .insert("line", ".node")
      //   .attr("class", "link")
      //   .attr("id", (d) => {
      //     return d.target.id + d.target.name + "link";
      //   })
      //   .attr("stroke", (d) => {
      //     return d.source.color || "#9ecae1";
      //   })
      // .on("mouseover", handleMouseOver)
      // .on("mouseout", handleMouseOut);

      // Update nodes.
      node = node
        .data(nodes, function (d) {
          return d.id;
        })
        //Adding for zooming and dragging of graphs *Start*
        .call(drag);
      // .on("dblclick", dblclick);

      node.exit().remove();

      var nodeEnter = node
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("id", (d) => {
          return d.id + d.name + "node";
        })
        .on("click", click)
        .on("touchstart", click, function (event) {
          event.preventDefault();
        })

        .call(force.drag);
      // .on("mouseover", nodeMouseOver)
      // .on("mouseout", nodeMouseOut);

      nodeEnter
        .append("circle")
        .attr("r", function (d) {
          const updatedRating =
            me.props && me.props.ratingObj[me.state.keyDimensionValue][d.FA_ID];
          d.averageRating = updatedRating ? updatedRating : d.averageRating;
          const r =
            (2 - d.level * 0.25) *
            (height / 75) *
            (d.level === 0
              ? 2
              : d.level === 1
              ? 1.75
              : d.level === 2
              ? 1.7
              : d.level === 3
              ? d.level === 3 && d.averageRating === 3
                ? 1.7
                : d.level === 3 && d.averageRating > 2.5 && d.averageRating < 3
                ? 1.65
                : d.level === 3 && d.averageRating > 2 && d.averageRating <= 2.5
                ? 1.5
                : d.level === 3 && d.averageRating === 2
                ? 1.45
                : 1.3
              : 1.25);
          d.r = r;
          return d.r;
        })
        .attr("class", "innerCircle")
        .attr("fill", color);

      // .attr("stroke", (d) => {
      //    return  "#3182bd";
      // });
      nodeEnter
        .append("circle")
        .attr("r", function (d) {
          const updatedRating =
            me.props && me.props.ratingObj[me.state.keyDimensionValue][d.FA_ID];
          d.averageRating = updatedRating ? updatedRating : d.averageRating;
          const r =
            (2 - d.level * 0.25) *
            (height / 75) *
            // (d.rating===3? 3: d.rating===2?1.25:1 )

            (d.level === 0
              ? 2
              : d.level === 1
              ? 1.75
              : d.level === 2
              ? 1.7
              : d.level === 3
              ? d.level === 3 && d.averageRating === 3
                ? 1.7
                : d.level === 3 && d.averageRating > 2.5 && d.averageRating < 3
                ? 1.65
                : d.level === 3 && d.averageRating > 2 && d.averageRating <= 2.5
                ? 1.5
                : d.level === 3 && d.averageRating === 2
                ? 1.45
                : 1.3
              : 1.25);

          d.r = r;
          return d.r;
        })
        .attr("id", (d) => {
          return d.id + d.name + "SelectedNode";
        })
        // .attr("class", "outerCircle"+ d.level)
        .attr("class", (d) => {
          return "outerCircle";
        })
        .attr("fill", "transparent")
        .attr("stroke", color)
        .style("opacity", 0);

      nodeEnter
        .append("foreignObject")
        .attr("x", (d) => -d.r)
        .attr("y", (d) => -d.r)
        .attr("width", (d) => {
          return 2 * d.r;
        })
        .attr("height", (d) => {
          return 2 * d.r;
        })
        // .attr("height", side)
        .append("xhtml:body")
        .attr("class", "outerCircleStyle")
        .append("span")
        // .style("font-size", function(d) {
        //     if (d.averageRating >= 2.5 && d.averageRating <= 3 ){
        //       return Math.min(2 * d.r, (2 * d.r)) + "%";
        //     }else if(d.averageRating >= 2 && d.averageRating <= 2.499){
        //       return Math.min(2 * d.r, (3 * d.r)) + "%";
        //     }else if(d.averageRating >= 1.5 && d.averageRating <= 1.999){
        //       return Math.min(2 * d.r, (3.5 * d.r)) + "%";
        //     }else{f
        //       return Math.min(2 * d.r, (4 * d.r)) + "%";
        //     }
        // })
        .html(function (d) {
          var textLength = d.name.length,
            text = d.name === 'Enterprise Risk' ? `${labelTitleEN}`: d.name === 'Standard' ? `${labelTitleST}` : d.name;
          if (d.averageRating === 3 && textLength > 50 && textLength > 0) {
            return (text = text.slice(0, 50) + "...");
          } else if (
            d.averageRating >= 1 &&
            d.averageRating <= 2 &&
            textLength > 25 &&
            textLength > 0
          ) {
            return (text = text.slice(0, 25) + "...");
          } else if (
            d.averageRating > 2 &&
            d.averageRating < 3 &&
            textLength > 35 &&
            textLength > 0
          ) {
            return (text = text.slice(0, 35) + "...");
          } else return text;
        })
        .attr("class", addNodeClass)
        // node.append("text")
        //.style("font-size", function(d) { return Math.min(2 * d.r, (2 * d.r - 8) / this.getComputedTextLength() * 24) + "px"; })
        // .text(function (d) {
        //   return d.name;
        // });
        // node.select("circle").style("fill", color);
        .style("font-size", function (d) {
          if (d.level === 3 && d.averageRating >= 1 && d.averageRating <= 3) {
            return Math.min(2 * d.r, 1.7 * d.r) + "%";
          } else {
            return Math.min(3 * d.r, 2 * d.r) + "%";
          }
        });
    };
    this.updateNode = update;
    update();
    function addNodeClass(d) {
      return (
        "nodeFontSize" +
        d.level +
        (d.level === 3 ? " rating_" + d.averageRating : "")
      );
    }

    path = linkContainer
      .selectAll("path")
      .data(force.links())
      .enter()
      .append("svg:path")
      .attr("class", function (d) {
        return "link source" + d.source.id + "  sourcelevel" + d.source.level;
      })
      // .attr("id", function (d) {
      //   console.log(d.source, "level");
      //   return "sourcelevel" + d.source.level;
      // })
      .attr("id", (d) => {
        return "link target" + d.target.id;
      })
      // .attr("class", "link")
      .attr("marker-end", "url(#end)")
      .attr("stroke", (d) => {
        return d.source.color || "#9ecae1";
      });
    // add the curvy lines
    function tick() {
      if (path) {
        path.attr("d", function (d) {
          var dx = d.target.x - d.source.x,
            dy = d.target.y - d.source.y,
            dr = Math.sqrt(dx * dx + dy * dy);
          return (
            "M" +
            d.source.x +
            "," +
            d.source.y +
            "A" +
            dr +
            "," +
            dr +
            " 0 0,1 " +
            d.target.x +
            "," +
            d.target.y
          );
        });

        node.attr("transform", function (d) {
          return "translate(" + d.x + "," + d.y + ")";
        });
      }
    }
    function hideChildNodes(nodes) {
      (nodes || []).forEach((n) => {
        hideChildNodes(n.children);
        if (n.level === 2 && !n._children) {
          toggleNodeAndLink(n, "link source" + n.id);
        }
      });
    }
    //Hide Child nodes on initial load
    hideChildNodes(this.root);
    update();
    function nodeMouseOver(d) {
      const sourceNode = document.getElementById(d.id + d.name + "node");
      if (
        sourceNode.style.opacity.length === 0 ||
        sourceNode.style.opacity === "1"
      ) {
        // tooltipDiv.transition().duration(200).style("opacity", 0.9);
        // tooltipDiv
        //   .html("<h6>" + d.name + "</h6>")
        //   .style("left", d3.event.pageX + "px")
        //   .style("top", d3.event.pageY + "px");
        const allLinks = document.getElementsByClassName("link");
        for (var i = 0; i < allLinks.length; i++) {
          allLinks[i].style.opacity = 0.2;
        }
        const allnodes = document.getElementsByClassName("node");
        for (i = 0; i < allnodes.length; i++) {
          allnodes[i].style.opacity = 0.8;
        }
        const sourceNode = document.getElementById(d.id + d.name + "node");
        sourceNode.style.opacity = 1;
        if (d.children) {
          for (i = 0; i < d.children.length; i++) {
            const sourceNode = document.getElementById(
              d.children[i].id + d.children[i].name + "node"
            );
            sourceNode.style.opacity = 1;
            const mouselinkEvent = document.getElementById(
              d.children[i].id + d.children[i].name + "link"
            );
            mouselinkEvent.style.opacity = 1;
          }
        }
      }
    }

    function handleMouseOver(d, j) {
      const mouselinkEvent = document.getElementById(
        d.target.id + d.target.name + "link"
      );
      if (
        mouselinkEvent.style.opacity.length === 0 ||
        mouselinkEvent.style.opacity === "1"
      ) {
        const allLinks = document.getElementsByClassName("link");
        for (var i = 0; i < allLinks.length; i++) {
          allLinks[i].style.opacity = 0.4;
        }

        mouselinkEvent.style.opacity = 1;

        const allnodes = document.getElementsByClassName("node");
        for (i = 0; i < allnodes.length; i++) {
          allnodes[i].style.opacity = 0.4;
        }
        const sourceNode = document.getElementById(
          d.source.id + d.source.name + "node"
        );
        sourceNode.style.opacity = 1;
        const targetNode = document.getElementById(
          d.target.id + d.target.name + "node"
        );
        targetNode.style.opacity = 1;
      }
    }

    //Adding for zooming and dragging of graphs *Start*
    function dblclick(d) {
      d3.event.preventDefault();
      d3.event.stopPropagation();
      d3.select(this).classed("fixed", (d.fixed = false));
    }

    function dragstart(d) {
      d3.event.sourceEvent.preventDefault();
      d3.event.sourceEvent.stopPropagation();
      d3.select(this).classed("fixed", (d.fixed = true));
    }

    function zoom() {
      var zoom = d3.event;
      svg.attr(
        "transform",
        "translate(" + zoom.translate + ")scale(" + zoom.scale + ")"
      );
    }
    //Adding for zooming and dragging of graphs *End*
    function color(d) {
      return (
        d.color ||
        (d._children
          ? "#3182bd" // collapsed package
          : d.children
          ? "#c6dbef" // expanded package
          : "#fd8d3c")
      ); // leaf node
    }

    // Returns a list of all nodes under the root.
    function flatten(data) {
      var nodes = [],
        i = 0;

      function recurse(node) {
        if (node.children) node.children.forEach(recurse);
        if (!node.id) node.id = ++i;
        nodes.push(node);
      }
      data.forEach((d) => {
        recurse(d);
      });
      return nodes;
    }
    // this.clonedRoot = JSON.parse(JSON.stringify(this.root));
  };

  componentDidMount() {
    const { i18n } = this.props;
    const lang = i18n.language
    this.setState({lang:lang})
    if (this.props.chartData?.FOCUSAREA?.EnterpriseRisks?.length) {
      this.props.fetchChartDetailsData("FOCUSAREA", lang);
      this.setState({ keyDimensionValue: "focusarea" }, () => {
        this.updateChart();
      });
    } else {
      this.props.fetchChartDetailsData("FOCUSAREA", lang);
      this.setState({ keyDimensionValue: "focusarea" });
    }
  }

  componentDidUpdate(prevsProps, prevState) {
    if(!(this.state.lang === prevsProps.i18n.language)) {
      this.setState({lang:prevsProps.i18n.language})
      this.props.fetchChartDetailsData("FOCUSAREA", prevsProps.i18n.language);
      this.setState({ keyDimensionValue: "focusarea" });   
    }
    if (
      this.props.chartData?.FOCUSAREA &&
      JSON.stringify(this.props.chartData) !==
        JSON.stringify(prevsProps.chartData)
    ) {
      this.updateChart();
    }
    return null;
  }

  updateChart() {
    try {
      this.root =
        this.state.keyDimensionValue === "focusarea"
          ? new EntRiskJson(this.props.chartData.FOCUSAREA).data
          : new StandardJson(this.props.chartData.STANDARDS).data;
      this.rootCopy = JSON.parse(JSON.stringify(this.root));
      d3.select("#canvas").remove();

      this.setState({ isPanelArrowShow: false });
      this.props.onUpdatePanelShowHideFlag(false);
      this.drawChart();
      this.initialZoomIn();
      this.setState({
        searchData: {
          data:
            this.state.keyDimensionValue === "focusarea"
              ? this.props.chartData.FOCUSAREA
              : this.props.chartData.STANDARDS,
        },
        chartData: this.props.chartData,
        loading: false,
        updatedRoot: this.root,
      });
    } catch (err) {
      this.displayMessage("Data is not available, Please try again later.");
    }
  }

  displayMessage = (msg) => {
    const containerId = document.getElementById("message-display-container");
    if (containerId) {
      containerId.innerHTML = "<h5 className='display-msg'>" + msg + "</h5>";
    }
    this.setState({ loading: false });
  };

  initialZoomIn = () => {
    var svg = this.svg;
    let scale = 3;
    svg.attr("transform", "translate(" + [0, 0] + ")scale(" + scale + ")");
    const frame = () => {
      if (scale <= 1) {
        scale = 1;
        clearInterval(id);
        //this.onZoomFit();
        if (isMobile) {
          this.fixZoomScalePosition(-0.01);
        } else {
          this.fixZoomScalePosition(0.25);
        }
      } else {
        scale -= 0.01;
        this.fixZoomScalePosition(-0.01);
      }
    };
    clearInterval(id);
    var id = setInterval(frame, 10);
  };

  hideUnselectedData = (data, opacity) => {
    data.isFiltered = opacity === "0.6";
    const sourceNode = document.getElementById(data.id + data.name + "node");
    sourceNode.style.opacity = opacity;
    const mouselinkEvent = document.getElementById(
      data.id + data.name + "link"
    );
    if (mouselinkEvent) {
      mouselinkEvent.style.opacity = opacity;
    }
    if (data.children) {
      data.children.forEach((child) => {
        this.hideUnselectedData(child, opacity);
      });
    }
  };

  onCheckBoxValueChange = (e) => {
    this.root.forEach((d) => {
      const ele = document.getElementById(d.name + "checkbox") || {
        checked: true,
      };
      this.hideUnselectedData(d, ele.checked ? 1 : 0.6);
    });
  };

  getScale = (element) => {
    let scale = 1;
    const i = element?.getAttribute("transform");
    if (i) {
      scale = Number(i.split("scale(").pop().split(")")[0]);
    }
    return scale;
  };

  /* On click of + icon*/
  onZoomIn = (e) => {
    this.isZoomInOut = true;
    var active = d3.select(".fixed");

    if (isDesktop && active.node()) {
      /* If already any node is selected */
      this.zoomScaleSelectedNode("zoomIn");
    } else if ((isMobile || isTablet) && this.clickedNode) {
      this.zoomOutNodeForMobile("zoomIn");
    } else {
      this.fixZoomScalePosition(0.5);
    }
  };

  /* On click of - icon*/
  onZoomOut = (e) => {
    this.isZoomInOut = true;
    var active = d3.select(".fixed");

    if (isDesktop && active.node()) {
      /* If already any node is selected */
      this.zoomScaleSelectedNode("zoomOut");
    } else if ((isMobile || isTablet) && this.clickedNode) {
      this.zoomOutNodeForMobile("zoomOut");
    } else {
      this.fixZoomScalePosition(-0.5);
    }
  };

  /* on click of  +/- icon change the scale and traslate value of network */
  zoomScaleSelectedNode = (zoomType) => {
    const data = 0.5;
    const element = document.getElementById("zoomableCanvas");
    const networkEle = document.getElementById("network");

    var width = networkEle.clientWidth,
      height = networkEle.clientHeight;
    let scaleNew = this.getScale(element);
    if (zoomType === "zoomIn") {
      scaleNew = scaleNew + data;
    } else {
      scaleNew = scaleNew - data;
    }

    var active = d3.select(".fixed");
    if (active.node()) {
      var bboxNew = active.node().getBBox();
      const d = active.node().__data__;
      bboxNew.x = d.px - d.r;
      bboxNew.y = d.py - d.r;
      var boundsNew = [
        [bboxNew.x, bboxNew.y],
        [bboxNew.x + bboxNew.width, bboxNew.y + bboxNew.height],
      ];

      var dx = boundsNew[1][0] - boundsNew[0][0],
        dy = boundsNew[1][1] - boundsNew[0][1],
        x = (boundsNew[0][0] + boundsNew[1][0]) / 2,
        y = (boundsNew[0][1] + boundsNew[1][1]) / 2,
        scale = Math.max(
          0.6,
          Math.min(scaleNew, 0.6 / Math.max(dx / width, dy / height))
        ),
        translate = [width / 2 - scale * x, height / 2 - scale * y];

      this.svg.attr(
        "transform",
        "translate(" + translate + ")scale(" + scale + ")"
      );
    }
  };

  zoomOutNodeForMobile = (zoomType) => {
    const id = this.clickedNode.id + this.clickedNode.name + "node";
    console.log("cw in zoomOutNodeForMobile", this.clickedNode);
    const element = document.getElementById("zoomableCanvas");
    const networkEle = document.getElementById("network");
    const node = document.getElementById(id);
    var width = networkEle.clientWidth,
      height = networkEle.clientHeight;
    let scaleNew = this.getScale(element);

    if (zoomType === "zoomIn") {
      scaleNew = scaleNew + 0.5;
    } else if (zoomType === "zoomOut") {
      scaleNew = scaleNew - 0.5;
    }

    if (node) {
      var bboxNew = node.getBBox();
      const d = this.clickedNode;
      bboxNew.x = d.px - d.r;
      bboxNew.y = d.py - d.r;
      var boundsNew = [
        [bboxNew.x, bboxNew.y],
        [bboxNew.x + bboxNew.width, bboxNew.y + bboxNew.height],
      ];

      var dx = boundsNew[1][0] - boundsNew[0][0],
        dy = boundsNew[1][1] - boundsNew[0][1],
        x = (boundsNew[0][0] + boundsNew[1][0]) / 2,
        y = (boundsNew[0][1] + boundsNew[1][1]) / 2,
        scale = Math.max(
          0.6,
          Math.min(scaleNew, 0.6 / Math.max(dx / width, dy / height))
        ),
        translate = [width / 2 - scale * x, height / 2 - scale * y];
      console.log("cw translate", scale, translate);
      this.svg.attr(
        "transform",
        "translate(" + translate + ")scale(" + scale + ")"
      );
    }
  };

  fixZoomScalePosition = (data) => {
    let element = document.getElementById("zoomableCanvas");
    const scale = this.getScale(element) + data;
    if (scale >= 0.5 && scale <= 8 && element) {
      this.zoom.scale(scale);
      this.zoom.translate([0, 0]);
      this.zoom.event(this.svg);

      element = document.getElementById("zoomableCanvas");
      const parentElemenet = document.getElementById("canvas");
      const svgData = element?.getBoundingClientRect();
      const parentData = parentElemenet?.getBoundingClientRect();
      const balWidth = (parentData.width - svgData.width) / 2;
      const offsetLeft = svgData.left - parentData.left;
      const balheight = (parentData.height - svgData.height) / 2;
      const offsetTop = svgData.top - parentData.top;
      this.svg.attr(
        "transform",
        "translate(" +
          [balWidth - offsetLeft, balheight - offsetTop] +
          ")scale(" +
          scale +
          ")"
      );
      this.zoom.translate([balWidth - offsetLeft, balheight - offsetTop]);
      this.zoom.event(this.svg);
    }
  };
  // onZoomFit = (e) => {
  //   this.svg.attr("transform", "translate(" + [0, 0] + ")scale(" + 1 + ")");
  // };

  onZoomFit = (e) => {
    this.isZoomInOut = false;
    this.clickedNode = null;
    var paddingPercent = 0.95;
    var bounds = this.svg.node().getBBox();
    var parent = this.svg.node().parentElement;
    var fullWidth = parent.clientWidth,
      fullHeight = parent.clientHeight;
    // var fullWidth = parent.clientWidth > 800 ? parent.clientWidth : 800,
    // fullHeight = parent.clientHeight > 800 ? parent.clientHeight : 800;

    var width = bounds.width,
      height = bounds.height;
    var midX = bounds.x + width / 2,
      midY = bounds.y + height / 2;
    if (width === 0 || height === 0) return; // nothing to fit
    var scale =
      (paddingPercent || 0.75) /
      Math.max(width / fullWidth, height / fullHeight);
    var translate = [
      fullWidth / 2 - scale * midX,
      fullHeight / 2 - scale * midY,
    ];

    //console.trace("zoomFit", translate, scale);
    // this.svg
    //  .transition()
    //  .duration(transitionDuration || 0) // milliseconds
    //  .call(zoom.translate(translate).scale(scale).event);
    this.zoom.scale(scale);
    this.zoom.translate(translate);
    this.zoom.event(this.svg);
    this.svg.attr(
      "transform",
      "translate(" + translate + ")scale(" + scale + ")"
    );
    this.fixZoomScalePosition(0.44);
  };

  showFilterPanel = (e) => {
    this.setState({
      showFilteroptions: !this.state.showFilteroptions,
      // showFilteroptions: true,
    });
  };

  updateFilter = (e) => {
    // let clone = JSON.parse(JSON.stringify(this.clonedRoot));
    console.log("Testing by Ani",e);
    const me = this;
    this.root = JSON.parse(JSON.stringify(me.rootCopy));
    if (this.root[0]) {
      this.updateAppliedFilter(this.root[0], e, false);
      this.setState({ updatedRoot: this.root, selectedNode: null });
      // this.root = clone;
      // this.updateNode();
      d3.select("#canvas").remove();
      this.drawChart();
    }
    // this.onZoomFit();
  };

  checkIsMatchExists = (filterArr, matchedArr, isObject = false) => {
    let isMatched = false;
    for (var i = 0; i < filterArr.length; i++) {
      var matchString = filterArr[i].value;
      if (!matchedArr) {
      }
      if (isObject) {
        for (var j = 0; j < matchedArr.length; j++) {
          var matchObjString = matchedArr[j].name;
          if (matchObjString === matchString) {
            isMatched = true;
            break;
          }
        }
      } else {
        if (matchedArr.includes(matchString)) {
          isMatched = true;
          break;
        }
      }
    }
    return isMatched;
  };

  checkIsMatchExistsWithString = (filterArr, name) => {
    if (!filterArr) {
      return false;
    }
    let isMatched = false;
    for (var i = 0; i < filterArr.length; i++) {
      var matchString = filterArr[i].value;

      if (name === matchString) {
        isMatched = true;
        break;
      }
    }
    return isMatched;
  };

  formatRating = (averageRating) => {
    if (isNaN(averageRating)) {
      return "";
    }

    return Math.ceil(averageRating)?.toString();
  };

  filterLeve21Data = (data, filters, isFiltered) => {
    data.children = (data.children || []).filter((d) => {
      if (
        (filters["year"] && filters["year"].length) ||
        (filters["rating"] && filters["rating"].length) ||
        (filters["eaSector"] && filters["eaSector"].length) ||
        (filters["SDG"] && filters["SDG"].length)||
        (filters["ESG"] && filters["ESG"].length)||
        (filters["mainProcess"] && filters["mainProcess"].length)
      ) {
        if (
          filters["year"] &&
          filters["year"].length &&
          !filters["year"].includes(d.Year_Of_Issue)
        ) {
          return false;
        }
        if (
          filters["rating"] &&
          filters["rating"].length &&
          !filters["rating"].includes(this.formatRating(d.averageRating))
        ) {
          return false;
        }
        if (
          filters["eaSector"] &&
          filters["eaSector"].length &&
          !this.checkIsMatchExists(filters["eaSector"], d.EA_sector, true)
        ) {
          return false;
        }
        if (
          filters["SDG"] &&
          filters["SDG"].length &&
          !this.checkIsMatchExists(filters["SDG"], d.sdg, true)
        ) {
          return false;
        }
        if (
          filters["ESG"] &&
          filters["ESG"].length &&
          !this.checkIsMatchExists(filters["ESG"], d.esg, true)
        ) {
          return false;
        }

        if (
          filters["mainProcess"] &&
          filters["mainProcess"].length &&
          !this.checkIsMatchExists(filters["mainProcess"], d.Main_process)
        ) {
          return false;
        }
      }

      return true;
    });

    return data.children;
  };

  filterLevel1Data = (data, filters, isFiltered) => {
    data.children = (data.children || []).filter((d) => {
      if (filters["standard"] && filters["standard"].length) {
        if (
          d.name &&
          !this.checkIsMatchExistsWithString(filters["standard"], d.name)
        ) {
          return false;
        }
      }

      const childrenData = this.filterLeve21Data(d, filters, isFiltered);
      if (childrenData.length > 0) {
        return true;
      } else {
        return false;
      }
    });

    return data.children;
  };

  updateAppliedFilter = (data, filters, isFiltered) => {
    if (this.state.keyDimensionValue === "focusarea") {
      data.children = (data.children || []).filter((d) => {
        if (filters["entRisk"] && filters["entRisk"].length) {
          if (
            d.name &&
            !this.checkIsMatchExistsWithString(filters["entRisk"], d.name)
          ) {
            return false;
          }
        }
        const childrenData = this.filterLevel1Data(d, filters, isFiltered);

        if (childrenData.length > 0) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      /* For Standard */
      if (
        data.children.length > 0 &&
        data.level === 0 &&
        data.category === "Standards"
      ) {
        if (filters["standard"] && filters["standard"].length) {
          const standardFilter = [];
          filters["standard"].forEach((element) => {
            standardFilter.push(element.value);
          });
          data.children = (data.children || []).filter((d) => {
            if (!standardFilter.includes(d.name)) {
              return false;
            }
            return true;
          });
        }
        (data.children || []).forEach((d) => {
          this.updateAppliedFilter(d, filters, isFiltered);
        });
      }

      if (data.level === 2) {
        data.children = (data.children || []).concat(
          data.filteredChildren || []
        );

        data.children = (data.children || []).filter((d) => {
          if (
            filters["year"] &&
            filters["year"].length &&
            !filters["year"].includes(d.Year_Of_Issue)
          ) {
            return false;
          }
          if (
            filters["rating"] &&
            filters["rating"].length &&
            !filters["rating"].includes(this.formatRating(d.averageRating))
          ) {
            return false;
          }
          if (
            filters["eaSector"] &&
            filters["eaSector"].length &&
            !this.checkIsMatchExists(filters["eaSector"], d.EA_sector, true)
          ) {
            return false;
          }
          if (
            filters["SDG"] &&
            filters["SDG"].length &&
            !this.checkIsMatchExists(filters["SDG"], d.sdg, true)
          ) {
            return false;
          }
          if (
            filters["ESG"] &&
            filters["ESG"].length &&
            !this.checkIsMatchExists(filters["ESG"], d.esg, true)
          ) {
            return false;
          }
          if (
            filters["standard"] &&
            filters["standard"].length &&
            !this.checkIsMatchExists(filters["standard"], d.standard, true)
          ) {
            return false;
          }
          if (
            filters["entRisk"] &&
            filters["entRisk"].length &&
            !this.checkIsMatchExists(filters["entRisk"], d.Enterprise_risks)
          ) {
            return false;
          }
          if (
            filters["mainProcess"] &&
            filters["mainProcess"].length &&
            !this.checkIsMatchExists(filters["mainProcess"], d.Main_process)
          ) {
            return false;
          }

          return true;
        });
      } else if (data.level === 1) {
        data.children = (data.children || []).concat(
          data.filteredChildren || []
        );
        (data.children || []).forEach((d) => {
          this.updateAppliedFilter(d, filters, isFiltered);
        });
      }
      if (data.level === 1 || data.level === 0) {
        this.filterParentNodes(data);
      }
    }
  };

  filterParentNodes = (node) => {
    // node.children = (node.children || []).concat(node.filteredChildren || []);
    // node.filteredChildren = [];
    node.children = node.children.filter((d) => {
      if ((d.children || []).length + (d._children || []).length > 0) {
        // this.filterParticularNode(d, false);
        return true;
      }
      // node.filteredChildren.push(d);
      // this.filterParticularNode(d, true);
      return false;
    });
  };

  filterParticularNode = (node, isFiltered) => {
    const targetNode = document.getElementById("link target" + node.id);
    node.isFiltered = isFiltered;
    if (targetNode) {
      targetNode.style.display = isFiltered ? "none" : "block";
    }
  };

  clearAllFilters = () => {
    this.updateFilter({});
  };
  componentWillUnmount() {
    this.clearAllFilters();
  }
  openFocusAreaModal = () => {
    this.setState({
      openModal: true,
    });
  };
  closeFocusAreaModal = () => {
    this.setState({
      openModal: false,
    });
    document.getElementsByClassName("network-wrapper")[0].scrollTo(0, 0);
    // window.location.reload();
    // const history = useHistory();
    // history.push("/focus-area");
    // useHistory().push("/focus-area");
    this.setState({ viewFocusAreaDetails: [] });
  };
  onSearch = (event, value) => {
    //console.log("Search value" + JSON.stringify(value));
    if (value) {
      var focusAreaArr = this.state.viewFocusAreaDetails.concat(value);
      //console.log('focus area array' + JSON.stringify(focusAreaArr[0].FA_Audit_Trail_PLAN));
      var selectedFocusArea = focusAreaArr[0];
      this.setState({ viewFocusAreaDetails: selectedFocusArea });
      this.openFocusAreaModal();
    }
  };

  selectedStandard = (e) => {
    this.setState({
      keyDimensionValue: e.target.value,
    });
  };
  /** Funtion that handles key dimension filtering
   * Bases on filter selected focusarea/standards, its corresponding json will be picked and rendered
   */
  applyKeyDimension = (e) => {
    const { i18n } = this.props;
    const lang = i18n.language
    var selectedValue = e.target.value;
    this.root = [];
    this.rootCopy = [];
    this.clearAllFilters();
    if (selectedValue.toUpperCase() === "FOCUSAREA") {
      this.setState(
        {
          keyDimensionValue: e.target.value,
          openPanel: false,
          searchData: {},
          selectedNode: null,
          loading: false,
        },
        () => {
          this.updateChart();
          console.log(
            "cw in FA",
            this.root,
            this.rootCopy,
            this.state.openPanel
          );
        }
      );
    } else if (selectedValue.toUpperCase() === "STANDARDS") {
      this.setState(
        {
          keyDimensionValue: e.target.value,
          openPanel: false,
          searchData: {},
          selectedNode: null,
          loading: false,
        },
        () => {
          if (
            !this.props.chartData.STANDARDS ||
            (this.props.chartData?.STANDARDS &&
              this.props.chartData.STANDARDS.length === 0)
          ) {
            this.props.fetchChartDetailsData("STANDARDS", lang);
            console.log("cw in if ST", this.root, this.rootCopy);
          } else {
            this.props.fetchChartDetailsData("STANDARDS", lang);
            this.updateChart();
            console.log("cw in else ST", this.root, this.rootCopy);
          }
        }
      );
    }
    // To clear and render chart with new json
    // d3.select("svg").remove();
    // this.drawChart();
  };
  closeFilterPanel = (e) => {
    this.setState({ showFilteroptions: false });
  };
  showHidePanel = () => {
    console.log("cw in showHidePanel", this.state.openPanel);

    this.setState({ openPanel: !this.state.openPanel });
  };
  render() {
    return (
      <>
        <div>
          <FocusAreaModel
            open={this.state.openModal}
            handleClose={this.closeFocusAreaModal}
            viewFocusAreaDetails={this.state.viewFocusAreaDetails}
            keyDimensionValue={this.state.keyDimensionValue}
          />
        </div>
        {this.state.loading ? <Spinner /> : null}
        <div className="network-wrapper">
          <div className="network-container">
            <div className="network-options">
              <img
                src={filterIcon}
                className={
                  this.state.showFilteroptions
                    ? "filterIconSelected"
                    : "filterIcon"
                }
                alt="filter"
                onClick={this.showFilterPanel}
              />
              <div style={{ flex: 1 }}></div>
              <img
                className="expandIcon"
                src={expandIcon}
                alt="expand"
                onClick={this.onZoomFit}
              />
              <img
                className="zoomInIcon"
                src={zoomInIcon}
                alt="zoom in"
                onClick={this.onZoomIn}
              />
              <img
                className="zoomOutIcon"
                src={zoomOutIcon}
                alt="zoom out "
                onClick={this.onZoomOut}
              />
            </div>
            <div>
              <SearchBar
                onSearch={this.onSearch}
                searchData={this.state.searchData}
              />
              <div id="network">
                <div id="message-display-container"></div>
                <Legend
                  showFilter={this.state.showFilteroptions}
                  keyDimensionValue={this.state.keyDimensionValue}
                />
                {/* {this.state.showFilteroptions && ( */}
                <FilterComponent
                  updateFilter={this.updateFilter}
                  applyKeyDimension={this.applyKeyDimension}
                  showFilteroptions={this.state.showFilteroptions}
                  keyDimensionValue={this.state.keyDimensionValue}
                  closeFilterPanel={this.closeFilterPanel}
                  selectedStandard={this.selectedStandard}
                />
                {/* )} */}
              </div>
            </div>
          </div>
          <InfoPanel
            open={this.state.open}
            openPanel={this.state.openPanel}
            data={this.state.updatedRoot}
            showHidePanel={this.showHidePanel}
            selectedNode={this.state.selectedNode}
            keyDimensionValue={this.state.keyDimensionValue}
          />
        </div>
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    chartData: makeSelectChartData(state),
    userInfo: state.login.userInfo,
    ratingObj: state.chart.ratingObj,
    isPanelShow: state.chart.isPanelShow,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    fetchChartDetailsData: (type,lang) => dispatch(fetchChartDetails(type,lang)),
    onUpdatePanelShowHideFlag: (flag) =>
      dispatch(updatePanelShowHideFlag(flag)),
  };
}

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withAITracking(reactPlugin, NetworkLinks, "Focus Area Ecosystem Page")));
