import { combineReducers } from "redux";
import chartReducer from "./reducers/chartReducer";
import loginReducer from "./reducers/loginReducer";

const rootReducer = combineReducers({
  chart: chartReducer,
  login: loginReducer,
});

export default rootReducer;
