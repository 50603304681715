import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { Button } from "@material-ui/core";
import { isMobile, isTablet, isDesktop,mobileModel } from "react-device-detect";
import Slide from "@material-ui/core/Slide";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import EllipsisTooltip from "react-ellipsis-tooltip";
import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
import ratedStar from "../../assets/icons/star(2).png";
import { setLoginData } from "../../actions/chartActions";
import FocusAreaModel from "../../components/FocusAreaModal";
import "./infopanel.css";
import FocusArea from "../../components/FocusArea";
import expand from "../../assets/icons/expand.png";
import collapse from "../../assets/icons/Icon-Chevron-Small-White-Down-01.png";
import buttonClose from "../../assets/icons/ButtonsCloseBlack.png";

const tableData = [
  {
    name: "Year of issue",
    value: "Year_Of_Issue",
  },
  {
    name: "Standards",
    value: "standard",
  },
  {
    name: "EA industry sectors",
    value: "EA_sector",
  },
  {
    name: "SDGs (Sust. Dev. Goals)",
    value: "sdg",
  },
  {
    name: "ESG",
    value: "esg",
  },  
  {
    name: "Macro process",
    value: "Main_process",
  },
  {
    name: "Enterprise risk",
    value: "Enterprise_risks",
  },
  {
    name: "Rating",
    value: "averageRating",
  }

];
// console.log("AK in test today",tableData);
const useStyles = makeStyles({
  root: {
    // width: 335,
    margin: "5px 20px",
    width: "90%",
    backgroundColor: "transparent",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  innerRoot: {
    // width: 335,
    margin: "1px 5px",
    width: "90%",
    backgroundColor: "transparent",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  iconColor: {
    color: "#fff",
  },
  accrodianMainLabelName: {
    color: "#fff",
    marginBottom: 0,
    width: "100%",
    marginRight: 0,
  },
  accrodianDetailsRoot: {
    padding: 0,
  },
  mainItem: {
    textAlign: "center",
    margin: 20,
  },
  proposeAgain: {
    textTransform: "unset",
    backgroundColor: "#0299D5",
    borderRadius: 0,
    fontWeight: 600,
  },
  arrowForward: {
    fill: "#fff",
    top: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "3em",
    padding: "5px",
    position: "absolute",
  },
  arrowBack: {
    fill: "#fff !important",
    top: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "3em",
    padding: "5px",
    position: "absolute",
  },
});
export const InfoPanel = (props) => {
  const { i18n, t } = useTranslation()
  const labelTitleEN = t("enterpriseRisk")
      const labelTitleST = t("Standard")
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [viewFocusAreaDetails, setViewFocusAreaDetails] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const openSlider = (data) => {
    // console.log('inside info panel handle open----'+ JSON.stringify(data))
   setViewFocusAreaDetails(data);
    setChecked(true);
    setDisabled(true);
  };
  const closeSlider = () => {
    setChecked(false);
    setDisabled(false);
  };
  const expandView = () => {
    setChecked(false);
    openFocusAreaModal();
  };
  const openFocusAreaModal = (data) => {
    // console.log("AK in VFAprops",props.data);
    // console.log("AK in VFA",data);
    setViewFocusAreaDetails(data);
    setOpenModal(true);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  const classes = useStyles();
  const handleClose = () => {
    // setViewFocusAreaDetails({});
    setOpenModal(false);
    setDisabled(false);
  };
  const panelInfo = data[0];
  let activeIndex = 0;
  if (props.selectedNode) {
    activeIndex = _.findIndex(panelInfo.children, {
      key: props.selectedNode.key.split(".")[0],
    });
  }
  const getRatingStarts = (value) => {
    const ratingArr = [];
    for (var i = 0; i < value; i++) {
      ratingArr.push(
        <img className="star-icon" src={ratedStar} alt="ratedStar" key={i} />
      );
    }
    return ratingArr;
  };
  if (!panelInfo || !panelInfo?.name || panelInfo.children.length === 0) {
    return "";
  }
 // console.log("Panel Info", panelInfo);
  const isExpandable = (key, selectedKey) => {
   // console.log("panel clicked");
    if (key && selectedKey) {
      const keys = key.split(".");
      const selectedKeyArray = selectedKey.split(".");
      let isExpanded = true;
      for (var i = 0; i < keys.length; i++) {
        if (keys[i] !== selectedKeyArray[i]) {
          isExpanded = false;
        }
      }
      return isExpanded;
    }
    return false;
  };

  // document.getElementById("expand_Panel").addEventListener("click", myFunction);

  // function myFunction() {
  //   // document.getElementById("demo").innerHTML = "YOU CLICKED ME!";
  //   setChecked(true);
  // }
  const updateFaCount = () => {
    var count = 0;
    const selectedNode = props?.selectedNode;
    if (selectedNode && selectedNode.level === 1) {
      selectedNode.children.map((item, i) => {
        const childs = item._children || item.children;
        const childCount = childs.length;
        count = count + childCount;
      });
    }
    if (selectedNode && selectedNode.level === 2) {
      const childs = selectedNode._children || selectedNode.children;
      count = childs.length;
    }
    if (selectedNode && selectedNode.level === 3) {
      count = 1;
    }
    return count;
  };
  const updatedRating =
    props.ratingObj[props.keyDimensionValue][viewFocusAreaDetails.FA_ID];
  return (
    <div key={props?.selectedNode?.key + "" + updatedRating}>
      <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
        <div className="panel-slider">
          <img
            className="close-icon"
            src={buttonClose}
            onClick={closeSlider}
            alt="close icon"
          />
          <img
            className="expand-icon"
            src={expand}
            onClick={expandView}
            alt="expand"
          />

          <FocusArea
            viewFocusAreaDetails={viewFocusAreaDetails}
            closeSlider={closeSlider}
            expandView={expandView}
            updatedRating={props.updatedRating}
          ></FocusArea>
        </div>
      </Slide>

      {isDesktop && props.isPanelShow && (
        <div className="rightInfoPanel" style={{ display: "flex" }}>
          <div
            id="expand_Panel"
            className={
              props.openPanel
                ? "expandCollapseOpen" +
                  (checked ? " sliderOpen" : " sliderClose")
                : "expandCollapse-close"
            }
          >
            {/* <div
          className={props.openPanel ? "arrow right" : "arrow left"}
        ></div> */}
            {props.openPanel ? (
              <ArrowForwardIcon
                onClick={checked ? closeSlider : props.showHidePanel}
                className={
                  isTablet
                    ? classes.arrowBack
                    : isDesktop
                    ? classes.arrowBack
                    : "arrowBackIcon"
                }
              />
            ) : (
              <ArrowBackIcon
                onClick={checked ? closeSlider : props.showHidePanel}
                className={
                  isTablet
                    ? classes.arrowForward
                    : isDesktop
                    ? classes.arrowForward
                    : "arrowForwardIcon"
                }
              />
            )}

            <Drawer
              BackdropProps={{ invisible: true }}
              className={isMobile ? "RightSideInfoPanel" : "RightSideInfoPanel"}
              // className={
              //   isMobile ? "BottomSideInfoPanel" :
              //   isTablet ? "RightSideInfoPanel" :
              //   "RightSideInfoPanel"
              // }
              // anchor={"right"}
              anchor={isDesktop ? "right" : isTablet ? "right" : "bottom"}
              open={props.openPanel}
              variant="persistent"
            >
              <div
                className={
                  panelInfo.name === "Standard" ? "title-b-css" : "title-css"
                }
              >
                {panelInfo.name === 'Enterprise Risk' ? `${labelTitleEN}`: `${labelTitleST}`}
              </div>
              <div className="content-wrapper">
                <div
                  className={
                    panelInfo.name === "Standard" ? "asset-b-css" : "asset-css"
                  }
                >
                  {panelInfo.children[activeIndex].name}
                </div>
                <div className="asset-desc-css">
                  {panelInfo.children[activeIndex].description}
                </div>
                <div className="focus-area-title-css">
                {t('Focus Areas')} ({updateFaCount()})
                </div>
                <div className={classes.root}>
                  {panelInfo.children[activeIndex].children.map((item, i) => {
                    const children = item._children || item.children;
                    if (
                      isExpandable(item.key, props?.selectedNode?.key) ||
                      item.key.split(".").length >
                        props?.selectedNode?.key.split(".").length
                    ) {
                      return (
                        <Accordion
                          defaultExpanded={isExpandable(
                            item.key,
                            props?.selectedNode?.key
                          )}
                        >
                          <AccordionSummary
                            expandIcon={
                              // <AddIcon className={classes.iconColor} />
                              <ExpandMoreIcon className={classes.iconColor} />
                            }
                            // collapseIcon={
                            //   <RemoveIcon className={classes.iconColor} />
                            // }
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id={`focusarea-actions1-header-${i}`}
                          >
                            <FormControlLabel
                              className={classes.accrodianMainLabelName}
                              aria-label="Acknowledge"
                              control={
                                <li
                                  className="fa-list-item"
                                  style={{ color: item.color }}
                                ></li>
                              }
                              label={`${item.name} (${children?.length})`}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            className={classes.accrodianDetailsRoot}
                          >
                            {/* starting inner accordians */}
                            <div className={classes.innerRoot}>
                              {children &&
                                children.map((item, k) => {
                                  const updatedRating =
                                    props.ratingObj[props.keyDimensionValue][
                                      item.FA_ID
                                    ];
                                  item.averageRating =
                                    updatedRating || item.averageRating;
                                  if (
                                    isExpandable(
                                      item.key,
                                      props?.selectedNode?.key
                                    ) ||
                                    item.key.split(".").length >
                                      props?.selectedNode?.key.split(".").length
                                  ) {
                                    return (
                                      <Accordion
                                        defaultExpanded={isExpandable(
                                          item.key,
                                          props?.selectedNode?.key
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            // <AddIcon
                                            //   className={classes.iconColor}
                                            // />
                                            <ExpandMoreIcon
                                              className={classes.iconColor}
                                            />
                                          }
                                          // collapseIcon={
                                          //   <RemoveIcon className={classes.iconColor} />
                                          // }
                                          aria-label="Expand"
                                          aria-controls="additional-actions1-content"
                                          id={`focusarea-inner-actions1-header-${k}`}
                                        >
                                          <FormControlLabel
                                            className={
                                              classes.accrodianMainLabelName
                                            }
                                            aria-label="Acknowledge"
                                            control={
                                              <li
                                                className="fa-list-item"
                                                style={{ color: item.color }}
                                              ></li>
                                            }
                                            label={`${item.name}`}
                                          />
                                        </AccordionSummary>
                                        <AccordionDetails
                                          className={
                                            classes.accrodianDetailsRoot
                                          }
                                        >
                                          <div>
                                            <div className="focus-area-desc-css">
                                              {item.content}
                                            </div>
                                            
                                            <table className="focus-area-table">
                                              {tableData.map((tableRow, i) => {
                                                if (i === 1) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      
                                                      <td>
                                                        
                                                        {item[tableRow.value]
                                                          .length > 1 ? (
                                                          <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description

                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {t('Multiple standards')}
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                if (i === 2){
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        {item[tableRow.value]
                                                          .length > 1 ? (
                                                          <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description

                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {t('Multiple industry sectors')}
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                if (i === 3 ) {
                                                  //if(item[tableRow.value]!=''){
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        
                                                        {item[tableRow.value]!=''?item[tableRow.value]
                                                          .length > 1 ? (
                                                          <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description
                                                              
                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {t('Multiple SDGs (Sust. Dev. Goals)')}
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        ):'-'}

                                                      </td>
                                                    </tr>
                                                  );
                                                        //}
                                                        //else{
                                                        //   return (null);
                                                        // }
                                                }
                                                if (i === 4 ) {
                                                  //if(item[tableRow.value]!=''){
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        
                                                        {item[tableRow.value]!=''?item[tableRow.value]
                                                          .length > 1 ? (
                                                            <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description
                                                              
                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name[0] +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {
                                                                  t('Multiple ESG Categories')
                                                                }
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name[0] +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        ):'-'}

                                                      </td>
                                                    </tr>
                                                  );
                                                        // }
                                                        // else{
                                                        //   return (null);
                                                        // }
                                                }
                                                
                                                if (i === 6) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        {/* {
                                                    props.selectedNode
                                                      .Enterprise_risks
                                                  } */}
                                                        {item[tableRow.value]}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                if (i === 7) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td key={updatedRating}>
                                                        {/* {getRatingStarts(
                                                    item[tableRow.value]
                                                  )} */}
                                                        <Rating
                                                          name="half-rating-read"
                                                          value={
                                                            item[tableRow.value]
                                                          }
                                                          precision={0.5}
                                                          readOnly
                                                          max={3}
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                return (
                                                  <tr key={i}>
                                                    <td className="infoPanelTableLeft">
                                                    {t(tableRow.name)}
                                                    </td>
                                                    <td>
                                                      {item[tableRow.value]}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </table>{" "}
                                            <div className={classes.mainItem}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={disabled}
                                                className={classes.proposeAgain}
                                                onClick={() => {
                                                  openFocusAreaModal(item);
                                                }}
                                              >
                                                {t('View Focus Area')}
                                              </Button>
                                            </div>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    );
                                  }
                                })}
                            </div>
                            {/* ending inner accordians */}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                  })}
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      )}
      {!isDesktop && props.isPanelShow && (
        <div className="rightInfoPanel" style={{ display: "flex" }}>
          <div
            id="expand_Panel"
            className={
              props.openPanel
                ? "expandCollapseOpen blink" +
                  (checked ? " sliderOpen" : " sliderClose")
                : "expandCollapse-close blink"
            }
          >
            {/* <div
            className={props.openPanel ? "arrow right" : "arrow left"}
          ></div> */}
            {props.openPanel ? (
              <ArrowForwardIcon
                onClick={checked ? closeSlider : props.showHidePanel}
                className={
                   isTablet || mobileModel== "Chromecast"
                    ? classes.arrowBack
                    : isDesktop
                    ? classes.arrowBack
                    : "arrowBackIcon"
                }
              />
            ) : (
              <ArrowBackIcon
                onClick={checked ? closeSlider : props.showHidePanel}
                className={
                  isTablet || mobileModel== "Chromecast"
                    ? classes.arrowForward
                    : isDesktop
                    ? classes.arrowForward
                    : "arrowForwardIcon"
                }
              />
            )}

            <Drawer
              BackdropProps={{ invisible: true }}
              className={isMobile ? "RightSideInfoPanel" : "RightSideInfoPanel"}
              // className={
              //   isMobile ? "BottomSideInfoPanel" :
              //   isTablet ? "RightSideInfoPanel" :
              //   "RightSideInfoPanel"
              // }
              // anchor={"right"}
              anchor={isDesktop ? "right" : isTablet ? "right" : "bottom"}
              open={props.openPanel}
              variant="persistent"
            >
              <div
                className={
                  panelInfo.name === "Standard" ? "title-b-css" : "title-css"
                }
              >
                {panelInfo.name === 'Enterprise Risk' ? `${labelTitleEN}`: `${labelTitleST}`}
              </div>
              <div className="content-wrapper">
                <div
                  className={
                    panelInfo.name === "Standard" ? "asset-b-css" : "asset-css"
                  }
                >
                  {panelInfo.children[activeIndex].name}
                </div>
                <div className="asset-desc-css">
                  {panelInfo.children[activeIndex].description}
                </div>
                <div className="focus-area-title-css">
                  {t('Focus Areas')} ({updateFaCount()})
                </div>
                <div className={classes.root}>
                  {panelInfo.children[activeIndex].children.map((item, i) => {
                    const children = item._children || item.children;
                    if (
                      isExpandable(item.key, props?.selectedNode?.key) ||
                      item.key.split(".").length >
                        props?.selectedNode?.key.split(".").length
                    ) {
                      return (
                        <Accordion
                          defaultExpanded={isExpandable(
                            item.key,
                            props?.selectedNode?.key
                          )}
                        >
                          <AccordionSummary
                            expandIcon={
                              // <AddIcon className={classes.iconColor} />
                              <ExpandMoreIcon className={classes.iconColor} />
                            }
                            // collapseIcon={
                            //   <RemoveIcon className={classes.iconColor} />
                            // }
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id={`focusarea-actions1-header-${i}`}
                          >
                            <FormControlLabel
                              className={classes.accrodianMainLabelName}
                              aria-label="Acknowledge"
                              control={
                                <li
                                  className="fa-list-item"
                                  style={{ color: item.color }}
                                ></li>
                              }
                              label={`${item.name} (${children?.length})`}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            className={classes.accrodianDetailsRoot}
                          >
                            {/* starting inner accordians */}
                            <div className={classes.innerRoot}>
                              {children &&
                                children.map((item, k) => {
                                  const updatedRating =
                                    props.ratingObj[props.keyDimensionValue][
                                      item.FA_ID
                                    ];
                                  item.averageRating =
                                    updatedRating || item.averageRating;
                                  if (
                                    isExpandable(
                                      item.key,
                                      props?.selectedNode?.key
                                    ) ||
                                    item.key.split(".").length >
                                      props?.selectedNode?.key.split(".").length
                                  ) {
                                    return (
                                      <Accordion
                                        defaultExpanded={isExpandable(
                                          item.key,
                                          props?.selectedNode?.key
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            // <AddIcon
                                            //   className={classes.iconColor}
                                            // />
                                            <ExpandMoreIcon
                                              className={classes.iconColor}
                                            />
                                          }
                                          // collapseIcon={
                                          //   <RemoveIcon className={classes.iconColor} />
                                          // }
                                          aria-label="Expand"
                                          aria-controls="additional-actions1-content"
                                          id={`focusarea-inner-actions1-header-${k}`}
                                        >
                                          <FormControlLabel
                                            className={
                                              classes.accrodianMainLabelName
                                            }
                                            aria-label="Acknowledge"
                                            control={
                                              <li
                                                className="fa-list-item"
                                                style={{ color: item.color }}
                                              ></li>
                                            }
                                            label={`${item.name}`}
                                          />
                                        </AccordionSummary>
                                        <AccordionDetails
                                          className={
                                            classes.accrodianDetailsRoot
                                          }
                                        >
                                          <div>
                                            <div className="focus-area-desc-css">
                                              {item.content}
                                            </div>
                                            <table className="focus-area-table">
                                              {tableData.map((tableRow, i) => {
                                                if (i === 1) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                        {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        {item[tableRow.value]
                                                          .length > 1 ? (
                                                          <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description

                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {
                                                                  t('Multiple standards')
                                                                }
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                if (i === 2) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        
                                                        {item[tableRow.value]
                                                          .length > 1 ? (
                                                          <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description

                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {
                                                                  t('Multiple industry sectors')
                                                                }
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                if (i === 3 ) {
                                                  //if(item[tableRow.value]!=''){
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        
                                                        {item[tableRow.value]!=''?item[tableRow.value]
                                                          .length > 1 ? (
                                                          <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description
                                                              
                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {
                                                                  t('Multiple SDGs (Sust. Dev. Goals)')
                                                                }
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        ):'-'}

                                                      </td>
                                                    </tr>
                                                  );
                                                        //}
                                                        //else{
                                                        //   return (null);
                                                        // }
                                                }
                                                if (i === 4 ) {
                                                  //if(item[tableRow.value]!=''){
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        
                                                        {item[tableRow.value]!=''?item[tableRow.value]
                                                          .length > 1 ? (
                                                            <EllipsisTooltip
                                                            placement="top"
                                                            tooltip={
                                                              // item[tableRow.value][i].name + " - " + item[tableRow.value][i].description
                                                              
                                                              item[
                                                                tableRow.value
                                                              ]?.map(
                                                                (d) =>
                                                                  " " +
                                                                  d.name[0] +
                                                                  " - " +
                                                                  d.description
                                                              )
                                                            }
                                                            isMultilineEllipsis
                                                            lineCount={1}
                                                            lineHeight={1}
                                                          >
                                                            <h1>
                                                              <span>
                                                                {
                                                                  t('Multiple ESG Categories')
                                                                }
                                                              </span>
                                                            </h1>
                                                          </EllipsisTooltip>
                                                        ) : (
                                                          item[
                                                            tableRow.value
                                                          ][0].name[0] +
                                                          " - " +
                                                          item[
                                                            tableRow.value
                                                          ][0].description
                                                        ):'-'}

                                                      </td>
                                                    </tr>
                                                  );
                                                        // }
                                                        // else{
                                                        //   return (null);
                                                        // }
                                                }
                                                if (i === 6) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td>
                                                        {/* {
                                                      props.selectedNode
                                                        .Enterprise_risks
                                                    } */}
                                                        {item[tableRow.value]}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                if (i === 7) {
                                                  return (
                                                    <tr key={i}>
                                                      <td className="infoPanelTableLeft">
                                                      {t(tableRow.name)}
                                                      </td>
                                                      <td key={updatedRating}>
                                                        {/* {getRatingStarts(
                                                      item[tableRow.value]
                                                    )} */}
                                                        <Rating
                                                          name="half-rating-read"
                                                          value={
                                                            item[tableRow.value]
                                                          }
                                                          precision={0.5}
                                                          readOnly
                                                          max={3}
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                                return (
                                                  <tr key={i}>
                                                    <td className="infoPanelTableLeft">
                                                    {t(tableRow.name)}
                                                    </td>
                                                    <td>
                                                      {item[tableRow.value]}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </table>{" "}
                                            <div className={classes.mainItem}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={disabled}
                                                className={classes.proposeAgain}
                                                onClick={() => {
                                                  openFocusAreaModal(item);
                                                }}
                                              >
                                                {t('View Focus Area')}
                                              </Button>
                                            </div>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    );
                                  }
                                })}
                            </div>
                            {/* ending inner accordians */}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                  })}
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      )}
      <FocusAreaModel
        open={openModal}
        handleClose={handleClose}
        viewFocusAreaDetails={viewFocusAreaDetails}
        keyDimensionValue={props.keyDimensionValue}
        updatedRating={updatedRating}
      />
    </div>
  );
};
export function mapDispatchToProps(dispatch) {
  return {
    onSetLoginData: (data) => dispatch(setLoginData(data)),
  };
}

const mapStateToProps = (state) => ({
  isAuthUser: state.login.isAuthUser || false,
  ratingObj: state.chart.ratingObj,
  isPanelShow: state.chart.isPanelShow,
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPanel);
