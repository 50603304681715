import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { setLoginData, fetchMultilingualStaticData } from "../../actions/chartActions";

export class User extends Component {
  constructor() {
    super();
    this.state = {
      login: false,
    };
  }
  componentDidMount() {
    var queryString = window.location.search;
    var codeString = queryString.split("&code=")[1];
    var code = codeString.split("&")[0];
    const optionsForVerifyToken = {
      method: "POST",
      data: {
        code: code,
      },
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },
      url: `${process.env.REACT_APP_API_URL}/validateUser`,
    };

    axios(optionsForVerifyToken)
      .then((userInfo) => {
        const userInfoData = {
          ...userInfo.data.auth_code,
          ...userInfo.data.userInfo,
        };
        sessionStorage.setItem(
          "access_token",
          userInfo.data.auth_code.access_token
        );
         this.props.fetchMultilingualStaticData()
        this.props.onSetLoginData(userInfoData);
        this.props.history.push("/home");
        this.props.subscribeToRefreshToken(userInfoData);
      })
      .catch((err) => this.handleErrors(err));
  }

  handleErrors(err) {
    if (err.response.status === 500) {
      window.location.href =
        "https://id.veracity.com/terms-of-use?mode=accept&redirect-url=" +
        window.location.origin;
    } else {
      this.props.history.push("/unathorize");
    }
   // console.log("cw in handleErrors", err);
  }
  render() {
    return <div style={{fontFamily: "DNV Display, San-serif", fontSize: "2.5em", display: "flex", justifyContent: "center", alignItems: "center", height: "600px"}}>Loading....</div>;
  }
}
export function mapDispatchToProps(dispatch) {
  return {
    onSetLoginData: (data) => dispatch(setLoginData(data)),
    fetchMultilingualStaticData: () => dispatch(fetchMultilingualStaticData()),
  };
}



const mapStateToProps = ({ login }) => ({
  isAuthUser: login.isAuthUser || false,
  userInfo: login.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
