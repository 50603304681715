import react, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "./FocusAreaModal.css";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateAverageRating } from "../actions/chartActions";
import closeIcon from "../assets/icons/Buttons-Close.png";


import download_icon from "../assets/icons/download-export.svg";

import expand_more from "../assets/icons/Button-Filter-PointingDown-01.svg";
import ratingIcon from "../assets/icons/star-blue.svg";
import FocusArea from "./FocusArea";
// import Doc from "./DocService";
import { appInsights } from "../AppInsights";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    backgroundColor: "#E9E9E9",
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    height: "90%",
    top: 0,
    //maxHeight: "87%",
    // padding: theme.spacing(2, 4, 3),
  },
}));
function FocusAreaModel(props) {
  // const bodyRef = react.createRef();
  const { i18n, t } = useTranslation()
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [ratingSelected, ratingStar] = useState(false);
  const [starsSelected, setStarsSelected] = useState(0);
  const componentRef = useRef();

  // useEffect(() => {
  //   if (removeTooltip) {
  //     createPdf(
  //       bodyRef.current,
  //       "Focus_Area_" + props.viewFocusAreaDetails.FA_ID,
  //       () => {
  //         setRemoveTooltip(false);
  //       }
  //     );
  //   }
  // }, [removeTooltip]);

  const downloadFile = () => {
    appInsights.trackEvent({
      name: "Downloaded",
      properties: {
        FATitle: props.viewFocusAreaDetails.name,
        faId: props.viewFocusAreaDetails.FA_ID,
        userId: props.userInfo.userId,
      },
    });
  };

  const openRating = () => {
    if (!ratingSelected) {
      setStarsSelected(0);
    }
    ratingStar(!ratingSelected);
  };

  const handleRatingSubmit = () => {
    const submittedData = {
      Rating: starsSelected,
      faId: props.viewFocusAreaDetails.FA_ID,
    };
    appInsights.trackEvent({
      name: "Rated",
      properties: {
        Rating: starsSelected,
        userId: props.userInfo.userId,
        FATitle: props.viewFocusAreaDetails.name,
        faId: props.viewFocusAreaDetails.FA_ID,
      },
    });
    const getParams = (rating) => {
      return {
        type: props.keyDimensionValue,
        FAID: props.viewFocusAreaDetails.FA_ID,
        rating: rating,
      };
    };

    const options = {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },
      data: submittedData,
      url: `${process.env.REACT_APP_API_URL}/updateRating`,
    };
    if (options.data.Rating !== 0) {
      axios(options)
        .then((data) => {
          ratingStar(false);
          if(data.data.message === "Rating has been updated")
          toast(t(data.data.message));
          var params = getParams(data.data.averageRating);
          // console.log('params'+ params);
          props.onUpdateAverageRating(params);
        })
        .catch((error) => {
          appInsights.trackException({
            error: new Error("API error"),
            severityLevel: "high",
          });
        });
    }

    //
  };

  const Star = ({ selected = false, onClick = (f) => f }) => (
    <div className={selected ? "star selected" : "star"} onClick={onClick} />
  );

  const StarRating = ({ totalStars }) => {
    return (
      <div className="star-rating">
        {[...Array(totalStars)].map((n, i) => (
          <Star
            key={i}
            selected={i < starsSelected}
            onClick={() => setStarsSelected(i + 1)}
          />
        ))}
      </div>
    );
  };

  const body = (
    <div id="popupModal" style={modalStyle} className={classes.paper}>
      <FocusArea
        viewFocusAreaDetails={props.viewFocusAreaDetails}
        updatedRating={props.updatedRating}
      ></FocusArea>
      <div ref={componentRef}>
        <FocusArea
          viewFocusAreaDetails={props.viewFocusAreaDetails}
          updatedRating={props.updatedRating}
          ref={componentRef}
          removeTooltip={true}
        ></FocusArea>
      </div>
      <div className="faPopUpLeft">
        <img
          className="chevron-icon"
          src={expand_more}
          alt="chevron"
          onClick={props.handleClose}
        />
      </div>
      <div className="faPopUp">
        {
          <img
            className="close-icon"
            src={closeIcon}
            onClick={props.handleClose}
            alt="close icon"
          />
        }

        <ReactToPrint
          documentTitle={"Focus_Area_" + props.viewFocusAreaDetails.FA_ID}
          trigger={() => (
            <div className="download-icon">
              <img src={download_icon} alt="download icon" />
            </div>
          )}
          content={() => componentRef.current}
          onAfterPrint={() => {
            downloadFile();
          }}
        />
        <div className="rating-icon">
          <img src={ratingIcon} onClick={openRating} alt="rating" />
          <div className={ratingSelected ? "ratingShow" : "ratingHide"}>
            <div >
              <img
                className={ratingSelected ? "close-icon-rating" : "ratingHide"}
                src={closeIcon}
                onClick={openRating}
                alt="close icon"
              />
            </div>            
            <p>{t('Rate this Focus Area')}</p>
            <StarRating totalStars={3} />
            <button
              className={
                starsSelected ? "apply-button" : "apply-button-disabled"
              }
              type="button"
              onClick={handleRatingSubmit}
            >
              {t('submit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ outline: 0, backgroundColor: "rgba(0, 0, 0, 0.8)" }}
      >
        {body}
      </Modal>
    </div>
  );
}
export function mapDispatchToProps(dispatch) {
  return {
    onUpdateAverageRating: (data) => dispatch(updateAverageRating(data)),
  };
}
function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo || {},
    chartData: state.chart.chartData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FocusAreaModel);
