import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";

const AuthRoute = (props) => {
  const { isAuthUser } = props;
  if (isAuthUser) return <Route {...props} />;
  else if (!isAuthUser) return null;

  return <Route {...props} />;
};

const mapStateToProps = ({ login }) => ({
  isAuthUser: login.isAuthUser || false,
});

export default connect(mapStateToProps)(AuthRoute);
