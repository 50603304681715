import { CoreUtils } from "@microsoft/applicationinsights-core-js";
import * as React from "react";

export default function withAITracking(
  reactPlugin,
  Component,
  componentName,
  className
) {
  if (
    componentName === undefined ||
    componentName === null ||
    typeof componentName !== "string"
  ) {
    componentName =
      (Component.prototype &&
        Component.prototype.constructor &&
        Component.prototype.constructor.name) ||
      "Unknown";
  }

  if (
    className === undefined ||
    className === null ||
    typeof className !== "string"
  ) {
    className = "";
  }

  return class extends React.Component {
    _mountTimestamp = 0;
    _firstActiveTimestamp = 0;
    _idleStartTimestamp = 0;
    _lastActiveTimestamp = 0;
    _totalIdleTime = 0;
    _idleCount = 0;
    _idleTimeout = 5000;
    _intervalId;

    componentDidMount() {
      this._mountTimestamp = CoreUtils.dateNow();
      this._firstActiveTimestamp = 0;
      this._totalIdleTime = 0;
      this._lastActiveTimestamp = 0;
      this._idleStartTimestamp = 0;
      this._idleCount = 0;

      this._intervalId = setInterval(() => {
        if (
          this._lastActiveTimestamp > 0 &&
          this._idleStartTimestamp === 0 &&
          CoreUtils.dateNow() - this._lastActiveTimestamp >= this._idleTimeout
        ) {
          this._idleStartTimestamp = CoreUtils.dateNow();
          this._idleCount++;
        }
      }, 100);
    }

    componentWillUnmount() {
      if (this._mountTimestamp === 0) {
        throw new Error(
          "withAITracking:componentWillUnmount: mountTimestamp is not initialized."
        );
      }
      if (this._intervalId) {
        clearInterval(this._intervalId);
      }

      if (this._firstActiveTimestamp === 0) {
        return;
      }

      const engagementTime = this.getEngagementTimeSeconds();
      if (engagementTime) {
        const additionalProperties = {
          pageDurationInSec: engagementTime,
          userId: this.props?.userInfo?.userId,
        };
        reactPlugin.trackPageView({
          name: componentName,
          properties: additionalProperties,
        });
      }
    }

    render() {
      return (
        <div
          onKeyDown={this.trackActivity}
          onMouseMove={this.trackActivity}
          onScroll={this.trackActivity}
          onMouseDown={this.trackActivity}
          onTouchStart={this.trackActivity}
          onTouchMove={this.trackActivity}
          className={className}
        >
          <Component {...this.props} />
        </div>
      );
    }

    trackActivity = (e) => {
      if (this._firstActiveTimestamp === 0) {
        this._firstActiveTimestamp = CoreUtils.dateNow();
        this._lastActiveTimestamp = this._firstActiveTimestamp;
      } else {
        this._lastActiveTimestamp = CoreUtils.dateNow();
      }

      if (this._idleStartTimestamp > 0) {
        const lastIdleTime =
          this._lastActiveTimestamp - this._idleStartTimestamp;
        this._totalIdleTime += lastIdleTime;
        this._idleStartTimestamp = 0;
      }
    };

    getEngagementTimeSeconds() {
      return (
        (CoreUtils.dateNow() -
          this._firstActiveTimestamp -
          this._totalIdleTime -
          this._idleCount * this._idleTimeout) /
        1000
      );
    }
  };
}
