import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Legend.css";
import Collapse from "@material-ui/core/Collapse";
import ratedStar from "../../assets/icons/star(2).png";
import unRatedStar from "../../assets/icons/star(2)-1.png";

const Legend = ({ showFilter, keyDimensionValue }) => {
  const { t } = useTranslation()
  const [faToggle, setFaToggle] = React.useState(false);
  const [ratingToggle, setRatingToggle] = React.useState(false);
  const [legendToggle, setLegendToggle] = React.useState(false);
  const [mobileView, setMobileView] = React.useState(true);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 768px)');
    if (mql.matches) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);
  const handleFAChange = () => {
    setFaToggle((prev) => !prev);
  };
  const handleRatingChange = () => {
    setRatingToggle((prev) => !prev);
  };
  const handleLegendChange = () => {
    setLegendToggle((prev) => !prev);
  };
  const legendItemsRisk = [
    {
      name: "Enterprise risk",
      color: "#B42126",
    },
    {
      name: "Standard",
      color: "#003591",
    },
    {
      name: "Focus areas related to main processes:",
    },
    {
      name: "Context",
      color: "#B56700",
    },
    {
      name: "Leadership",
      color: "#2B6173",
    },
    {
      name: "Planning",
      color: "#FFF377",
    },
    {
      name: "Support",
      color: "#99D9F0",
    },
    {
      name: "Operation",
      color: "#91FFB4",
    },
    {
      name: "Performance evaluation",
      color: "#A1AAE6",
    },
    {
      name: "Improvement",
      color: "#14B5BF",
    },
  ];

  const legendItemsStandard = [
    {
      name: "Standard",
      color: "#003591",
    },
    {
      name: "mainProcess",
      color: "#009FDA",
    },
    {
      name: "Focus areas related to enterprise risks:",
    },
    {
      name: "Business Continuity",
      color: "#B56700",
    },
    {
      name: "Operating",
      color: "#91FFB4",
    },
    {
      name: "Products",
      color: "#14B5BF",
    },
    {
      name: "Quality",
      color: "#FFF377",
    },
    {
      name: "Safety",
      color: "#99D9F0",
    },
    {
      name: "Supply Chain",
      color: "#A1AAE6",
    },
    {
      name: "Sustainability & Environment",
      color: "#3F9C35",
    },
    {
      name: "Other risks",
      color: "#B42126",
    },
  ];

  return (
    <div
      className="legend-container"
      style={{ left: !showFilter ? "5px" : "5px" }}
    >
      {!mobileView && (<div className="legend-header">{t('legend')}</div> )}
      {mobileView && (<div style ={{display:"flex"}}>
        <div className="legend-header">{t('legend')}</div>
            <div onClick={handleLegendChange}>
              <div className="plus-container">
                <div
                  className={legendToggle ? "minus-icon" : "plus-icon"}
                ></div>
              </div>
            </div>
          </div>)}

      {keyDimensionValue === "focusarea" && !mobileView
        ? legendItemsRisk.map((d, id) => {
            return (
              <div key={id} style={{lineHeight: 0}}>
                {id < 2 ? (
                  <div className="legend-item">
                    <div
                      className="color-icon"
                      style={{ backgroundColor: d.color }}
                    ></div>{" "}
                    <div>{t(d.name)}</div>
                  </div>
                ) : null}

                {id === 2 ? (
                  <div className="legend-subheader">
                    <div className="color-icon-none"></div> <div>{t(d.name)}</div>
                    <div>
                      <div onClick={handleFAChange}>
                        <div className="plus-container">
                          <div
                            className={faToggle ? "minus-icon" : "plus-icon"}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {id >= 3 ? (
                  <Collapse in={faToggle}>
                    <div className="legend-item">
                      <div
                        className="color-icon"
                        style={{ backgroundColor: d.color }}
                      ></div>{" "}
                      <div>{t(d.name)}</div>
                    </div>
                  </Collapse>
                ) : (
                  ""
                )}
              </div>
            );
          })
        : ""}
      {keyDimensionValue === "standards" && !mobileView
        ? legendItemsStandard.map((d, id) => {
            return (
              <div key={id}>
                {id < 2 ? (
                  <div className="legend-item">
                    <div
                      className="color-icon"
                      style={{ backgroundColor: d.color }}
                    ></div>{" "}
                    <div>{t(d.name)}</div>
                  </div>
                ) : null}

                {id === 2 ? (
                  <div className="legend-subheader">
                    <div className="color-icon-none"></div> <div>{t(d.name)}</div>
                    <div>
                      <div onClick={handleFAChange}>
                        <div className="plus-container">
                          <div
                            className={faToggle ? "minus-icon" : "plus-icon"}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {id >= 3 ? (
                  <Collapse in={faToggle}>
                    <div className="legend-item">
                      <div
                        className="color-icon"
                        style={{ backgroundColor: d.color }}
                      ></div>{" "}
                      <div>{t(d.name)}</div>
                    </div>
                  </Collapse>
                ) : (
                  ""
                )}
              </div>
            );
          })
        : ""}
    {!mobileView && (
      <div style={{lineHeight: 0}}>
        <div className="legend-subheader">
          <div>{t('focusArea')}</div>
          <div>
            <div onClick={handleRatingChange}>
              <div className="plus-container">
                <div
                  className={ratingToggle ? "minus-icon" : "plus-icon"}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <Collapse in={ratingToggle}>
          <div className="rating-container">
            <div className="three-rating"></div>
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
          </div>
          <div className="rating-container">
            <div className="two-rating"></div>
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={unRatedStar} alt="star" />
          </div>
          <div className="rating-container">
            <div className="one-rating"></div>
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={unRatedStar} alt="star" />
            <img className="legend-rating-icon" src={unRatedStar} alt="star" />
          </div>
        </Collapse>
      </div> )}

    <Collapse in={legendToggle}>
      {keyDimensionValue === "focusarea" && mobileView
        ? legendItemsRisk.map((d, id) => {
            return (
              <div key={id} style={{lineHeight: 0}}>
                {id < 2 ? (
                  <div className="legend-item">
                    <div
                      className="color-icon"
                      style={{ backgroundColor: d.color }}
                    ></div>{" "}
                    <div>{t(d.name)}</div>
                  </div>
                ) : null}

                {id === 2 ? (
                  <div className="legend-subheader">
                    <div className="color-icon-none"></div> <div>{t(d.name)}</div>
                    <div>
                      <div onClick={handleFAChange}>
                        <div className="plus-container">
                          <div
                            className={faToggle ? "minus-icon" : "plus-icon"}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {id >= 3 ? (
                  <Collapse in={faToggle}>
                    <div className="legend-item">
                      <div
                        className="color-icon"
                        style={{ backgroundColor: d.color }}
                      ></div>{" "}
                      <div>{t(d.name)}</div>
                    </div>
                  </Collapse>
                ) : (
                  ""
                )}
              </div>
            );
          })
        : ""}
      {keyDimensionValue === "standards" && mobileView
        ? legendItemsStandard.map((d, id) => {
            return (
              <div key={id}>
                {id < 2 ? (
                  <div className="legend-item">
                    <div
                      className="color-icon"
                      style={{ backgroundColor: d.color }}
                    ></div>{" "}
                    <div>{t(d.name)}</div>
                  </div>
                ) : null}

                {id === 2 ? (
                  <div className="legend-subheader">
                    <div className="color-icon-none"></div> <div>{t(d.name)}</div>
                    <div>
                      <div onClick={handleFAChange}>
                        <div className="plus-container">
                          <div
                            className={faToggle ? "minus-icon" : "plus-icon"}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {id >= 3 ? (
                  <Collapse in={faToggle}>
                    <div className="legend-item">
                      <div
                        className="color-icon"
                        style={{ backgroundColor: d.color }}
                      ></div>{" "}
                      <div>{t(d.name)}</div>
                    </div>
                  </Collapse>
                ) : (
                  ""
                )}
              </div>
            );
          })
        : ""}
    {mobileView && (
      <div style={{lineHeight: 0}}>
        <div className="legend-subheader">
          <div>{t('focusArea')}</div>
          <div>
            <div onClick={handleRatingChange}>
              <div className="plus-container">
                <div
                  className={ratingToggle ? "minus-icon" : "plus-icon"}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <Collapse in={ratingToggle}>
          <div className="rating-container">
            <div className="three-rating"></div>
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
          </div>
          <div className="rating-container">
            <div className="two-rating"></div>
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={unRatedStar} alt="star" />
          </div>
          <div className="rating-container">
            <div className="one-rating"></div>
            <img className="legend-rating-icon" src={ratedStar} alt="star" />
            <img className="legend-rating-icon" src={unRatedStar} alt="star" />
            <img className="legend-rating-icon" src={unRatedStar} alt="star" />
          </div>
        </Collapse>
      </div> )}
    </Collapse>
    </div>
  );
};

export default Legend;
