import { fetchData } from "../api";

export const loadChartData = (data, type) => {
  return {
    type: "CHART_LOAD_SUCCESS",
    payload: {
      data,
      type,
    },
  };
};

export const loadLangData = (data) => {
  return {
    type: "LANG_LOAD_SUCCESS",
    payload: {
      data
    },
  };
};

export const setLoginData = (data) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: data,
  };
};
export const updateAverageRating = (data) => {
  return {
    type: "REFRESH_RATING",
    payload: data,
  };
};

export function fetchChartDetails(type, lang) {
  return function (dispatch) {
    return fetchData(
      `${process.env.REACT_APP_API_URL}${
        type === "FOCUSAREA" ? "/getHeatMapEntRisk?lang="+lang : "/getHeatMapStandards?lang="+lang
      }`
    ).then(({ data }) => {
      dispatch(loadChartData(data, type));
    });
  };
}

export const loadMasterData = (data, type) => {
  return {
    type: "MASTER_LOAD_SUCCESS",
    payload: {
      data,
    },
  };
};

export function fetchMasterDetails() {
  return function (dispatch) {
    return fetchData(`${process.env.REACT_APP_API_URL}/getMasterData`).then(
      ({ data }) => {
        dispatch(loadMasterData(data));
      }
    );
  };
}

export function updatePanelShowHideFlag(flag) {
  //console.log("cw in updatePanelShowHideFlag", flag);
  return {
    type: "UPDATE_PANELFLAG",
    isPanelArrow: flag,
  };
}



export function fetchMultilingualStaticData() {
  //console.log('fetchMultilingualStaticData called')
  return function (dispatch) {
    return fetchData(
      `${process.env.REACT_APP_API_URL}${"/getUILabelTrans"}`
    ).then(({ data }) => {
      dispatch(loadLangData(data));
    });
  };
}