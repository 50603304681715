import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
//import axios from "axios";
import "./index.css";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

class Video extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     videoUrl: "abc",
  //   };
  // }
  // componentDidMount() {
  //   const options = {
  //     method: "GET",
  //     headers: { Authorization: sessionStorage.getItem("access_token") },
  //     url: `${process.env.REACT_APP_API_URL}/getVideoLink`,
  //   };
  //   axios(options)
  //     .then((response) => {
  //       this.setState({ videoUrl: response.data.blobUrl });
  //     })
  //     .catch((error) => {
  //       console.log("cw in getVideoLink error", error);
  //     });
  // }

  render() {
    const { t } = this.props;
    // const videoURL = process.env.REACT_APP_HOME_PAGE_VIDEO_URL;
   // console.log("Home page video", videoURL);
    return (
      <div className="img-container-2">
        {/* <iframe src={videoURL} frameborder="0" allowfullscreen></iframe> */}
        <iframe
          src={t('videoURL')}
          width="auto"
          height="auto"
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="allowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
        ></iframe>
        {/* <div className="video-player-wrapper">
          <div className="video-player">
            <ArrowRightIcon></ArrowRightIcon>
            <video
              width="600"
              height="500"
              key={this.state.videoUrl}
              controls
              autoPlay
            >
              <source src={this.state.videoUrl} type="video/mp4" />
            </video>
          </div>
        </div> */}
      </div>
    );
  }
}
export default withTranslation()(Video);
