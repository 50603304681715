import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import NetworkChart from "../../assets/images/DataViz-RisksFirst-010.png";
import "./index.css";

export const Banner = () => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <div className="content-container">
          <div className="content-title">{t('title')}</div>
          <div className="content-description">
          {t('description')}
          </div>
          <NavLink className="focus-area" to="/focus-area">
          {t('focus-area')} <i className="arrow right"></i>
          </NavLink>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} style={{padding: 0, margin: 0}}>
        <div className="img-container">
          <img src={NetworkChart} alt="NetworkChart" className="NetworkChart" />
        </div>
      </Grid>
    </Grid>
  );
};
export default Banner;
