import React, { Component } from "react";
import { connect } from "react-redux";

export class Login extends Component {
  constructor() {
    super();
    this.state = {
      login: false,
    };
  }
  componentDidMount() {
    const loginUrl = `https://login.veracity.com/a68572e3-63ce-4bc1-acdc-b64943502e9d/oauth2/v2.0/authorize?p=b2c_1a_signinwithadfsidp&scope=openid%20offline_access%20https%3A%2F%2Fdnvb2cprod.onmicrosoft.com%2F83054ebf-1d7b-43f5-82ad-b2bde84d7b75%2Fuser_impersonation&response_type=code&response_mode=query&client_id=${
      process.env.REACT_APP_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_BASE_URL
    )}%2Fuser&state=oQ8PLw0Rwd9nMIxN_yP6-`;
    const url = `${loginUrl}`;
    window.location.href = url;
  }
  render() {
    return <div style={{fontFamily: "DNV Display, San-serif", fontSize: "2.5em", display: "flex", justifyContent: "center", alignItems: "center", height: "600px"}}>Loading... </div>;
  }
}
export default connect(null, null)(Login);
