const INITIAL_STATE = {
  chartData: {
    STANDARDS: [],
    FOCUSAREA: [],
  },
  masterData: [],
  ratingObj: {
    focusarea: {},
    standards: {},
  },
  isPanelShow: false,
};

const chartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHART_LOAD_SUCCESS":
      const chartData = {
        ...state.chartData,
        [action.payload.type]: action.payload.data,
      };

      return {
        ...state,
        chartData,
      };

    case "MASTER_LOAD_SUCCESS":
      return {
        ...state,
        masterData: action.payload.data,
      };
    case "REFRESH_RATING":
      const params = action.payload;
      if (params.type === "focusarea") {
        return {
          ...state,
          ratingObj: {
            ...state.ratingObj,
            focusarea: {
              ...state.ratingObj.focusarea,
              [params.FAID]: params.rating,
            },
          },
        };
      } else if (params.type === "standards") {
        return {
          ...state,
          ratingObj: {
            ...state.ratingObj,
            standards: {
              ...state.ratingObj.standards,
              [params.FAID]: params.rating,
            },
          },
        };
      }

    case "UPDATE_PANELFLAG":
      return {
        ...state,
        isPanelShow: action.isPanelArrow,
      };
    default:
      return state;
  }
};

export default chartReducer;
