import React, { useState, useEffect } from "react";
import "./DrawerComponent.css";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CssBaseline,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { useHistory, useLocation, NavLink } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { isDesktop, isMobile } from "react-device-detect";


const logOutURL = process.env.REACT_APP_LOGOUT_URL;
const TabsList = [
  {
    name: "Home",
    path: "/home",
  },
  { name: "Focus area ecosystem", path: "/focus-area" },
  { name: "Propose a focus area", path: "/proposals" },
  { name: "Logout", path: logOutURL },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    height: "24px",
    maxWidth: "24px",
    position: "relative",
    top: "18px",
    fill: "",
    marginLeft: "0",
    marginRight: "10px",
    "& .MuiListItem-root": {
      backgroundColor: "#fff",
      opacity: "0.25 !important",
    },
    "& .Mui-selected": {
      backgroundColor: "#fff",
      opacity: "0.25 !important",
    }
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  toolBar: {
    minHeight: "24px",
    height: "24px",
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  openDrawer:{
    width: "50% !important",
    maxWidth: "220px",
    minWidth: "185px",
    top: "79px !important",
  }
}));

const DrawerComponent = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation()
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(history.location.pathname);
  useEffect(() => {
    setValue(history.location.pathname);
  }, [location]);
  const handleChange = (event, item) => {
    setValue(item.path);
    history.push(item.path);
    setOpenDrawer(false);
    if(item.name === "Logout"){
      window.location.href=logOutURL;
    }
  };

  let isAdmin = false;
  const users = process.env.REACT_APP_ADMIN_PERMISSIONS.split(" ");
  if (users && users.length > 0) {
    isAdmin = true;
  }
  if (isAdmin && !TabsList[3] && !isMobile && isDesktop) {
    TabsList.push({ name: "Admin", path: "/admin" });
  }
  // if (isMobile && !TabsList[3]) {
  //   TabsList.push({ name: "Log out", path: logOutURL });
  // }

  return (
    <>
      <CssBaseline />
      <Drawer
        anchor="right"
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        className={classes.openDrawer}
        
      >
        <List>
          {TabsList.map((item, key) => {
            return (
              <ListItem button selected={item.path === value}>
                <ListItemIcon
                  value={value}
                  onClick={(e) => handleChange(e, item)}
                >
                  <ListItemText label={item.name} key={key} value={item.path}>
                    {t(item.name)}
                  </ListItemText>
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolBar}>
            <IconButton
              onClick={() => setOpenDrawer(true)}
              edge="center"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};
export default DrawerComponent;
