/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const fetchData = (api) => {
  const config = {
    headers: {
      Authorization: sessionStorage.getItem("access_token"),
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
    },
  };
  return axios.get(api, config).catch((error) => {
    const appInsights = useAppInsightsContext();
    appInsights.trackException({
      error: new Error("API error"),
      severityLevel: "high",
    });
  });
};
