import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { isTablet, isDesktop } from "react-device-detect";
import searchIcon from "../../assets/icons/Icon-Search-Grey.png";

import "./SearchBar.css";
import "bootstrap/dist/css/bootstrap.min.css";



const SearchBar = ({ onSearch, searchData }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState([]);
  const [mobileView, setMobileView] = React.useState(true);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 1024px)');
    if (mql.matches) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);
  const parseEntRisk = (result) => {
    if (result && result.data) {
      var risks = result.data.EnterpriseRisks;
      var lev1 = risks && risks[0] && risks[0].EnterpriseRisksName;
      // console.log('entRisk'+ JSON.stringify(lev1));
      var optionsArr = [];
      var uniqueOptionsArr = [];
      if (lev1 && lev1.length > 0) {
        lev1.forEach((data1) => {
          // console.log('lev1 data1' + JSON.stringify(data1));
          var enterpriseRisk = data1.name;
          if (data1.Standards) {
            var lev2 = data1.Standards;
            lev2.forEach((data2) => {
              //var standard = data2.name;
              // console.log('lev2 data2' + JSON.stringify(data2));
              if (data2.FocusAreas) {
                var lev3 = data2.FocusAreas;
                lev3.forEach((data3) => {
                  //data3.standard = standard;
                  data3.Enterprise_risks = enterpriseRisk;
                  var easector = Array.isArray(data3.EA_sector)
                    ? data3.EA_sector
                    : data3.EA_sector;
                  var standard = Array.isArray(data3.standard)
                    ? data3.standard
                    : data3.standard;
                  data3.EA_sector = easector;
                  data3.standard = standard;
                  optionsArr.push(data3);
                  // console.log('optionsArr'+ JSON.stringify(optionsArr));
                  // Filtering the duplicate focus areas and displaying unique options
                  uniqueOptionsArr = optionsArr.filter(
                    (elem, index) =>
                      optionsArr.findIndex((obj) => obj.name === elem.name) ===
                      index
                  );
                  // console.log('uniqueOptionsArr'+ JSON.stringify(uniqueOptionsArr));
                });
              }
            });
          }
        });
      }
      //sorting by focus area name
      uniqueOptionsArr.sort((a, b) => a.name.localeCompare(b.name));
      setOptions(uniqueOptionsArr);
    }
  };
  const parseStandard = (result) => {
    console.log("result" + JSON.stringify(result));
    var standards = result.data.Standards;
    var lev1 = standards[0].StandardName;
    // console.log('entRisk'+ JSON.stringify(lev1));
    var optionsArr = [];
    var uniqueOptionsArr = [];
    lev1.forEach((data1) => {
      // console.log("lev1 data1" + JSON.stringify(data1));
      // var standard = data1.name;
      if (data1.Mainprocesses) {
        var lev2 = data1.Mainprocesses;
        lev2.forEach((data2) => {
          var mainProcess = data2.name;
          // console.log('lev2 data2' + JSON.stringify(data2));
          if (data2.FocusAreas) {
            var lev3 = data2.FocusAreas;
            lev3.forEach((data3) => {
              //data3.standard = standard;
              data3.Main_process = mainProcess;
              var easector = Array.isArray(data3.EA_sector)
                ? data3.EA_sector
                : data3.EA_sector;
              var standard = Array.isArray(data3.standard)
                ? data3.standard
                : data3.standard;
              data3.EA_sector = easector;
              data3.standard = standard;
              optionsArr.push(data3);
              // Filtering the duplicate focus areas and displaying unique options
              uniqueOptionsArr = optionsArr.filter(
                (elem, index) =>
                  optionsArr.findIndex((obj) => obj.name === elem.name) ===
                  index
              );
            });
          }
        });
      }
    });
    //sorting by focus area name
    uniqueOptionsArr.sort((a, b) => a.name.localeCompare(b.name));
    setOptions(uniqueOptionsArr);
  };
  const loadSearchData = () => {
    searchData && searchData.data && searchData.data.Standards
      ? parseStandard(searchData)
      : parseEntRisk(searchData);
  };

  // useEffect(() => {
  //   let url = `${process.env.REACT_APP_API_URL}/getHeatMapEntRisk`
  //   fetchData(url).then((result) => {
  //       parseEnterpriseData(result);
  //   })
  //   console.log("searchdata" + JSON.stringify(searchData));
  //   loadSearchData();
  // }, []);
  return (
    <div className="SearchBar">
      <Autocomplete
        id="highlights-demo"
        size={"small"}
        className="SearchBarHighlights"
        onChange={onSearch}
        options={options}
        // disableListWrap
        // onInputChange={(event, newInputValue, reason) => {
        //         setSearchValue(newInputValue)
        //     }
        //   }
        getOptionLabel={(option) => (option.name ? option.name : "")}
        renderInput={(params) => (
          <div className="SearchBarWrapper" ref={params.InputProps.ref}>
            <img src={searchIcon} alt="Search" className="SearchIcon" />
            <input
              style={{ width: 700 }}
              id="search"
              className="search-input"
              type="search"
              {...params.inputProps}
              placeholder={t('search')}
              onFocus={loadSearchData}
            />
          </div>
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          // const FA = option.Standard;
          // console.log("FA" + JSON.stringify(FA));
          const parts = parse(option.name, matches);
          // console.log("rendering.....FA" + FA);
          // console.log("parts" + JSON.stringify(parts));
          return (
            <div className="container">
              <Grid container xs = {12} spacing={2}>
              { !mobileView ? (<Grid item xs={10}>
                <div >
                  {" "}
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        display: "inline",
                        fontWeight: part.highlight ? 700 : 400,
                        color: part.highlight ? "blue" : "",
                        textDecoration: part.highlight ? "underline" : "",
                      }}
                    >
                      {part.text}{" "}
                    </span>
                  ))}{" "}
                </div>
                </Grid>)
                : (<Grid item xs={11}>
                  <div >
                    {" "}
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          display: "inline",
                          fontWeight: part.highlight ? 700 : 400,
                          color: part.highlight ? "blue" : "",
                          textDecoration: part.highlight ? "underline" : "",
                        }}
                      >
                        {part.text}{" "}
                      </span>
                    ))}{" "}
                  </div>
                  </Grid>
                )}
                { !mobileView ? (<Grid item xs={2}>
                <div>
                  <button
                    type="button"
                    style={{
                      backgroundColor: option.color ? option.color : "white",
                      color: "black",
                    }}
                    className="btn btn-sm fabutton"
                  >
                   {"Focus Area"}
                  </button>
                </div>
                </Grid>)
                : (<Grid item xs={1}>
                <div>
                  <button
                    type="button"
                    style={{
                      backgroundColor: option.color ? option.color : "white",
                      color: "black",
                    }}
                    className="btn btn-sm fabutton"
                  >
                   {" "}
                  </button>
                </div>
                </Grid>)}
                </Grid>
                {/* <div className="col-2">({FA}) </div> */}
              </div>
          );
        }}
      />
    </div>
  );
};
export default SearchBar;
