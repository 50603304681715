import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Select from "react-select";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router";
import _ from "lodash";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import withAITracking from "../../withAITracking.jsx";
import "./index.css";
import close_button from "../../assets/icons/Button-Delete.png";
import AddActivity from "./AddActivity";
import { reactPlugin } from "../../AppInsights";




const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1) + 2,
    textTransform: "unset",
    font: "normal normal normal 18px/21px Arial",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "10% !important",
    "& .MuiBackdrop-root": {
      backgroundColor: "#000",
      opacity: "0.8 !important",
      top: "0 !important",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  sendbutton: {
    textTransform: "unset",
    // margin: theme.spacing(1),
    backgroundColor: "#0299D5",
    margin: "8px",
    padding: "0.5em 1em",
    font: "normal normal bold 20px/24px Arial",
    "&.Mui-disabled": {
      font: "normal normal bold 20px/24px Arial",
      padding: "0.5em 1em",
      letterSpacing: "0px",
      color: "#888888",
      opacity: 1,
    },
  },
  gotoLiabrary: {
    textTransform: "unset",
    backgroundColor: "#0299D5",
    width: "220px",
  },
  main: {
    backgroundColor: "transparent",
    width: "90%",
    height: "auto !important",
    margin: "0 auto",
    padding: "5%",
    textAlign: "center",
  },
  mainItem: {
    textAlign: "center",
    margin: 20,
  },
  title: {
    color: "#fff",
    opacity: 1,
  },
  proposeAgain: {
    textTransform: "unset",
    backgroundColor: "#36842D",
    width: "220px",
  },
}));

const customStyles = {
  menu: (provided, state) => ({
    ...provided,

    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 20,
    width: "95%",
    minHeight: "45px",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
export function Proposals(props) {
  const { i18n, t } = useTranslation()
  const location = useLocation();
  const appInsights = useAppInsightsContext();
  appInsights.trackMetric("Component 'MyComponent' is in use");
  const classes = useStyles();
  const history = useHistory();
  const [fields, setFields] = useState({
    plan: [],
    act: [],
    check: [],
    do: [],
  });
  const [errors, setErrors] = useState({});
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedSDGRecords, setSelectedSDGRecords] = useState([]);
  const [selectedESGRecords, setSelectedESGRecords] = useState([]);
  const [selectedStandradRecords, setSelectedStandradRecords] = useState([]);
  const [selectedEnterPriseRiskRecords, setSelectedEnterPriseRiskRecords] =
    useState([]);
  const [selectedMacroProcessRecords, setSelectedMacroProcessRecords] =
    useState([]);
  const [selectedFA, setSelectedFA] = useState([])
  const [selectedLang, setSelectedLang] = useState({value: 'en', label: 'English'})
  const [masterData, setMasterData] = useState([]);
  const [optionsStandrads, setOptionsStandrads] = useState([]);
  const [EAOptions, setEAOptions] = useState([]);
  const [SDGOptions, setSDGOptions] = useState([]);
  const [ESGOptions, setESGOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [enterpriseRiskOptions, setEnterpriseRiskOptions] = useState([]);
  const [macroprocessOptions, setMacroprocessOptions] = useState([]);
  const [fa, setFA] = useState([]);
  const [filterBySelectedFA, setFilterBySelectedFA] = useState({});
  const [resetFormKey, setResetFormKey] = useState(0);
  const [submitable, setSubmitable] = useState(false);
  const [isKeyValueChanged, setKeyValueChanged] = useState(false);
  const [langs,setLangs] = useState([])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getDropdownData = (data, type) => {
    //console.log(data);
    //console.log(type);
    const newData = [];
    data.map((item, i) => {
     // console.log(item);
      //console.log(i);
      if (item.type === type) {
        if(item.type=="ESG"){
          newData.push({
            label: item.id[3] + (item.subject ? "-" + item.subject : ""),
            value: item.itemValue,
            id: item.id,
          });}
        else{
        newData.push({
          label: item.itemValue + (item.subject ? " - " + item.subject : ""),
          value: item.itemValue,
          id: item.id,
        });}
             
      }
      //console.log(newData);
    });

    return newData;
  };

  const getDropdownDefaultData = (data, selectedStringArr) => {   
    //console.log("ANi in gddd",selectedStringArr);
    const newData = [];
    selectedStringArr.map((selectedString, i) => {
      data.map((item, i) => {
        if (item.id === selectedString.id) {
          newData.push(item);
        }
      });
    });

    return newData;
  };

  const filterData = (data) => {
   return data.filter(item => item.lang === i18n.language)
  }

  const filterFA = (data) => {
    /* return data.filter(item => item.lang === 'en') */
    const tempFA = []
    for(let i=0;i<data.length;i++) {
       if(data[i].lang === 'en') {
        tempFA.push({value: data[i].faId, label: data[i].FATitle, lang: data[i].lang})
       }
    }
    return tempFA
   }
   const findBySelectedFA = (data,selectedFA) => {
    return data?.find(obj => obj.faId == selectedFA.value && obj.lang === 'en')
   }

  useEffect(() => {
    var isSubmitable =
      props && props.activeRecordParams ? props.submitable : true;
    setSubmitable(isSubmitable);
    const options = {
      method: "GET",
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },

      url: `${process.env.REACT_APP_API_URL}/getMasterData`,
    };
    axios(options)
      .then((response) => {
        setMasterData(response.data);
        setLangs(props.langInfo?.data[0]?.availableLang)
        const standradsData = getDropdownData(filterData(response.data), "standard");
        const eaSectorData = getDropdownData(filterData(response.data), "eaSector");
        const  sdgData = getDropdownData(filterData(response.data), "SDG");
        const esgData = getDropdownData(filterData(response.data), "ESG");
        const enterpriseRiskData = getDropdownData(
          filterData(response.data),
          "enterpriseRisk"
        );
        const macroprocessData = getDropdownData(filterData(response.data), "macroprocess");
        setOptionsStandrads(standradsData);
        setEAOptions(eaSectorData);
        setSDGOptions(sdgData);
        setESGOptions(esgData);
        setEnterpriseRiskOptions(enterpriseRiskData);
        setMacroprocessOptions(macroprocessData);
        setFA(filterFA(props.rowData))

        if (props.edit || props.readonlyFA) {
          setSelectedStandradRecords(
            getDropdownDefaultData(
              standradsData,
              props.activeRecordParams.standard
            )
          );
          setSelectedMacroProcessRecords(
            getDropdownDefaultData(
              macroprocessData,
              props.activeRecordParams.macroprocess
            )
          );
          setSelectedEnterPriseRiskRecords(
            getDropdownDefaultData(
              enterpriseRiskData,
              props.activeRecordParams.enterpriseRisk
            )
          );

          setSelectedRecords(
            getDropdownDefaultData(
              eaSectorData,
              props.activeRecordParams.eaSector
            )
          );

          setSelectedSDGRecords(
            getDropdownDefaultData(
              sdgData,
              props.activeRecordParams.sdg
            )
          );

          setSelectedESGRecords(
            getDropdownDefaultData(
              esgData,
              props.activeRecordParams.esg
            )
          );
          //console.log("Ani in fetching EA",selectedRecords);
        //console.log("Ani in fetching SDG",selectedSDGRecords);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          error: new Error("API error"),
          severityLevel: "high",
        });
      });
  }, [i18n.language]);
  const getTextAreasData = (content) => {
    //console.log(content);
    const filtered = content.filter(function (el) {
      return el !== null && el !== "";
    });

    return filtered;
  };
  useEffect(() => {
    if (props.edit || props.readonlyFA) {
      const fields = {
        title: props.activeRecordParams.FATitle,
        rationale: props.activeRecordParams.faRational,
        plan: getTextAreasData(props.activeRecordParams.plan),
        do: getTextAreasData(props.activeRecordParams.do),
        check: getTextAreasData(props.activeRecordParams.check),
        act: getTextAreasData(props.activeRecordParams.act),
      };
      setFields(fields);
      setSelectedLang(props.langInfo?.data[0]?.availableLang.find(obj => obj.value == props.activeRecordParams.lang ));
      setSelectedFA(fa?.filter(obj => obj.value == props.activeRecordParams.faId));
    }
  }, [props.activeRecordParams, props.edit, props.readonlyFA,fa]);
  useEffect(() => {  
    if(Object.keys(filterBySelectedFA).length != 0 && filterBySelectedFA.constructor === Object){
      setSelectedStandradRecords(
        getDropdownDefaultData(
          optionsStandrads,
          filterBySelectedFA.standard
        )
      );
      setSelectedMacroProcessRecords(
        getDropdownDefaultData(
          macroprocessOptions,
          filterBySelectedFA.macroprocess
        )
      );
      setSelectedEnterPriseRiskRecords(
        getDropdownDefaultData(
          enterpriseRiskOptions,
          filterBySelectedFA.enterpriseRisk
        )
      );
      setSelectedRecords(
        getDropdownDefaultData(
          EAOptions,
          filterBySelectedFA.eaSector
        )
      );
      setSelectedSDGRecords(
        getDropdownDefaultData(
          SDGOptions,
          filterBySelectedFA.sdg
        )
      );
  
      setSelectedESGRecords(
        getDropdownDefaultData(
          ESGOptions,
          filterBySelectedFA.esg
        )
      );
    }
  }, [filterBySelectedFA]);

  const handleChange = (e, name, i) => {
    const data = { ...fields };
    if (_.isArray(data[name])) {
      data[name][i] = e.target.value;
    } else {
      data[e.target.name] = e.target.value;
    }

    setFields(data);
  };
  const handleEaClose = (e, a, b) => {};
  const onEAChange = (selectedData) => {
    setKeyValueChanged(true)
    setSelectedRecords(selectedData);
  };
  const onSDGChange = (selectedData) => {
    setKeyValueChanged(true)
    setSelectedSDGRecords(selectedData);
  };
  const onESGChange = (selectedData) => {
    setKeyValueChanged(true)
    setSelectedESGRecords(selectedData);
  };
  const onStandradChange = (selectedData) => {
    setKeyValueChanged(true)
    setSelectedStandradRecords(selectedData);
  };
  const onEnterpriseRiskChange = (selectedData) => {
    setKeyValueChanged(true)
    setSelectedEnterPriseRiskRecords([selectedData]);
  };
  const onMacroprocessChange = (selectedData) => {
    setKeyValueChanged(true)
    setSelectedMacroProcessRecords([selectedData]);
  };
  const onFAChange = (selectedData) => {
    setFilterBySelectedFA(findBySelectedFA(props.rowData,selectedData))
    setSelectedFA([selectedData]);
  };
  const onSetLangChange= (selectedData) => {
    setSelectedLang(selectedData);
  };
  const submitProposalsForm = (e) => {
    const submittedData = {
      faId: props.edit || props.readonlyFA ? props.activeRecordParams.faId : (props.newFA && selectedLang.value != 'en' ? selectedFA[0].value : ''),
      lang: props.newFA ? selectedLang.value : i18n.language,
      FATitle: fields.title,
      faRational: fields.rationale,
      plan: getTextAreasData(fields.plan),
      do: getTextAreasData(fields.do),
      check: getTextAreasData(fields.check),
      act: getTextAreasData(fields.act),
      standard: selectedStandradRecords.map((item) => item.id),
      sdg: selectedSDGRecords.map((item) => item.id),
      esg: selectedESGRecords.map((item) => item.id),
      eaSector: selectedRecords.map((item) => item.id),
      enterpriseRisk: selectedEnterPriseRiskRecords.map((item) => item.id), //[selectedEnterPriseRiskRecords.id],
      macroprocess: selectedMacroProcessRecords.map((item) => item.id), //[selectedMacroProcessRecords.id],
      release:
        props.edit || props.readonlyFA
          ? props.activeRecordParams.release
          : new Date().getFullYear(),
      averageRating:
        props.edit || props.readonlyFA
          ? props.activeRecordParams.averageRating
          : 0,
      createdby: props.userInfo.email[0],
      creationDate: new Date(),
      Modifiedby: props.userInfo.email[0],
      modifiedon: new Date(),
      isActive: true,
      IsKeyUpdated: props.edit && isKeyValueChanged && selectedLang.value == 'en' ? "true": "false"
    };
    const options = {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Ocp_Apim_Subscription_Key}`,
      },
      data: submittedData,
      url:
        props.edit || props.newFA || props.readonlyFA
          ? `${process.env.REACT_APP_API_URL}/saveFocusArea`
          : `${process.env.REACT_APP_API_URL}/saveProposedFocusArea`,
    };
    axios(options)
      .then((data) => {
        if (props.onClose) {
          props.onClose();
        } else {
          handleOpen();
        }
        if (props.newFA) {
          props.loadAdminData("FOCUSAREAECOSYSTEM");
        }
        if(props.edit)
        toast("Focus area has been updated");
        else
        toast("Focus area has been created");
      })
      .catch((error) => {
        appInsights.trackException({
          error: new Error("API error"),
          severityLevel: "high",
        });
      });
  };

  const removeItem = (arr, i, type) => {
    setKeyValueChanged(true)
    arr.splice(i, 1);
    const newArr = [...arr];
    if (type === "standrad") {
      setSelectedStandradRecords(newArr);
    }
    else if(type === "SDG"){
      setSelectedSDGRecords(newArr);
    }
    else if(type === "ESG"){
      setSelectedESGRecords(newArr);
    } else {
      setSelectedRecords(newArr);
    }
  };
  const removeEmptyValuesFromArray = (arr) => {
    const filtered = arr.filter(function (el) {
      return el !== null && el !== "";
    });

    return filtered;
  };
  // All fields are mandatory for add new FA page
  const isSubmitted = () => {
    if (
      !fields.title ||
      !fields.rationale ||
      removeEmptyValuesFromArray(fields.plan).length === 0 ||
      removeEmptyValuesFromArray(fields.act).length === 0 ||
      removeEmptyValuesFromArray(fields.check).length === 0 ||
      removeEmptyValuesFromArray(fields.do).length === 0 ||
      selectedStandradRecords.length === 0 ||
      selectedRecords.length === 0 ||
      selectedMacroProcessRecords.length === 0 ||
      selectedEnterPriseRiskRecords.length === 0
    ) {
      return true;
    }
    return false;
  };
  // Title and rationale mandatory for propose FA page
  const isMandatory = () => {
    if (
      !fields.title ||
      !fields.rationale
      // selectedStandradRecords.length === 0 ||
      // selectedRecords.length === 0 ||
      // selectedMacroProcessRecords.length === 0 ||
      // selectedEnterPriseRiskRecords.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={location.pathname == '/proposals' ? "proposol-screen": "proposol-screen modalView"} key={resetFormKey}>
      <div className="proposol-title-wrapper">
        <div className="proposol-title">{t('contributeEco')}</div>
        <div className="proposol-title-desc"></div>{t('submitFocus')}
      </div>

      <div className="proposol-form-wrapper">
        <form>
          <div className="group-wrapper">
            <div className="group1">
              <div className="ProposalLeftSection">
                {props.newFA ?
                (<div>
                <label className="group-heading required">{t('Language')}</label>
                <Select
                  options={langs}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedLang}
                  defaultValue={{value: 'en', label: 'English'}}
                  styles={customStyles}
                  onChange={onSetLangChange}
                  onClose={handleEaClose}
                  placeholder={t('select')}
                  isDisabled={props.edit ? true : false}
                />
                <div className="errorMsg">{errors.title}</div>
                {
                selectedLang.value != 'en' ? 
                <div>
                <label className="group-heading required">{t('Associated Focus Area')}</label>
                <Select
                  options={fa}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedFA}
                  styles={customStyles}
                  onChange={onFAChange}
                  onClose={handleEaClose}
                  placeholder={t('select')}
                  isDisabled={props.edit ? true : false}
                />
                <div className="errorMsg">{errors.title}</div></div> :''}
                </div>):''}
                
                <label className="group-heading required">{t('title1')}</label>
                <input
                  type="text"
                  className="title-text"
                  name="title"
                  placeholder={t('enterTitle')}
                  value={fields.title}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  // disabled={props.newFA}
                />
                <div className="errorMsg">{errors.title}</div>

                <label className="group-heading required">{t('rationale')}</label>
                <textarea
                  type="textarea"
                  name="rationale"
                  className="RationaleTextArea"
                  placeholder={t('enterRatinale')}
                  value={fields.rationale}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  // disabled={props.newFA}
                />
                <div className="errorMsg">{errors.rationale}</div>
              </div>
              <div className="ProposalRightSection">
                <label
                  className=
                    {props.edit ? "required groupHeading" : props.newFA ? "required groupHeading" : "groupHeading"}
                  
                  htmlFor="industrysectors"
                >
                 {t('applicableIS')}
                </label>
                {selectedLang.value == 'en' ? 
                (<ReactMultiSelectCheckboxes
                  options={EAOptions}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedRecords}
                  styles={customStyles}
                  onChange={onEAChange}
                  onClose={handleEaClose}
                />):null}
                <div className="errorMsg">{errors.industrySectors}</div>
                {selectedRecords.map((item, id) => {
                  return (
                    <div
                      className="applied-filter-option-item"
                      key={item.label + id}
                    >
                      {selectedLang.value == 'en' ?
                      (<img
                        className="filter-close-icon"
                        src={close_button}
                        onClick={()=> removeItem(selectedRecords, id, "ea")}
                        alt="close"
                      />):null}
                      {item.label}
                    </div>
                  );
                })}
                
                <label
                  className="groupHeading"
                  htmlFor="SDG"
                >
                  {t('sdgs')}
                </label>
                {selectedLang.value == 'en' ? (
                  <ReactMultiSelectCheckboxes
                  options={SDGOptions}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedSDGRecords}
                  styles={customStyles}
                  onChange={onSDGChange}
                  onClose={handleEaClose}
                />
                ):null}
                
                <div className="errorMsg">{errors.sdg}</div>
                {selectedSDGRecords.map((item, id) => {
                  return (
                    <div
                      className="applied-filter-option-item"
                      key={item.label + id}
                    >
                      {selectedLang.value == 'en'?
                      (<img
                        className="filter-close-icon"
                        src={close_button}
                        onClick={() => removeItem(selectedSDGRecords, id, "SDG")}
                        alt="close"
                      />):null}
                      {item.label}
                    </div>
                  );
                })}
                
                <label
                  className="groupHeading"
                  htmlFor="ESG"
                >
                  {t('esg')}
                </label>
                {selectedLang.value == 'en' ? (
                <ReactMultiSelectCheckboxes
                  options={ESGOptions}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedESGRecords}
                  styles={customStyles}
                  onChange={onESGChange}
                  onClose={handleEaClose}
                />):null}
                <div className="errorMsg">{errors.esg}</div>
                {selectedESGRecords.map((item, id) => {
                  return (
                    <div
                      className="applied-filter-option-item"
                      key={item.label + id}
                    >
                       {selectedLang.value == 'en'?
                      (
                      <img
                        className="filter-close-icon"
                        src={close_button}
                        onClick={() => removeItem(selectedESGRecords, id, "ESG")}
                        alt="close"
                      />):null}
                      {item.label}
                    </div>
                  );
                })}
                
                <label                  
                  className={props.edit ? "required groupHeading" : props.newFA ? "required groupHeading" : "groupHeading"}
                  htmlFor="applicablesectors"
                >
                  {t('app_std')}
                </label>
                {selectedLang.value == 'en' ? (
                <ReactMultiSelectCheckboxes
                  options={optionsStandrads}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedStandradRecords}
                  styles={customStyles}
                  onChange={onStandradChange}
                  onClose={handleEaClose}
                />):null}
                <div className="errorMsg">{errors.applicableStandrads}</div>
                {selectedStandradRecords.map((item, id) => {
                  return (
                    <div
                      className="applied-filter-option-item"
                      key={item.label + id}
                    >
                      {selectedLang.value == 'en'?
                      (<img
                        className="filter-close-icon"
                        src={close_button}
                        onClick={() =>removeItem(selectedStandradRecords, id, "standrad")}
                        alt="close"
                      />):null}
                      {item.label}
                    </div>
                  );
                })}
                <label
                  className={props.edit ? "required groupHeading" : props.newFA ? "required groupHeading" : "groupHeading"}
                  htmlFor="applicablesectors"
                >
                 {t('enterRisk')}
                </label>
                <Select
                  options={enterpriseRiskOptions}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedEnterPriseRiskRecords}
                  styles={customStyles}
                  onChange={onEnterpriseRiskChange}
                  onClose={handleEaClose}
                  placeholder={t('select')}
                  isDisabled={selectedLang.value != 'en'}
                />
                <div className="errorMsg">{errors.applicableStandrads}</div>
                <label
                  className={props.edit ? "required groupHeading" : props.newFA ? "required groupHeading" : "groupHeading"}
                  htmlFor="applicablesectors"
                >
                  {t('mainProcess')}
                </label>
                <Select
                  options={macroprocessOptions}
                  className="custom-dropdown"
                  placeholderButtonLabel={t('select')}
                  rightAligned={false}
                  width={"95%"}
                  value={selectedMacroProcessRecords}
                  styles={customStyles}
                  onChange={onMacroprocessChange}
                  onClose={handleEaClose}
                  placeholder={t('select')}
                  isDisabled={selectedLang.value != 'en'}
                />
                <div className="errorMsg">{errors.applicableStandrads}</div>
              </div>
            </div>
            <div className="group2">
              <div className="ProposalLeftSection">
                <AddActivity
                  title={t('auditTrailPlan')}
                  defaultCheckBoxCount={props.edit|props.readonlyFA ? fields.plan.length : 3}
                  value={fields.plan}
                  handleChange={handleChange}
                  name="plan"
                  placeholder={t('enterCheckpoint')}
                  newFA={props.newFA}
                  readonlyFA={props.readonlyFA}
                />
                <AddActivity
                  title={t('auditTrailCheck')}
                  defaultCheckBoxCount={props.edit|props.readonlyFA ? fields.check.length : 3}
                  value={fields.check}
                  handleChange={handleChange}
                  name="check"
                  placeholder={t('enterCheckpoint')}
                  newFA={props.newFA}
                  readonlyFA={props.readonlyFA}
                />
              </div>
              <div className="ProposalRightSection">
                <AddActivity
                  title={t('auditTrailDO')}
                  defaultCheckBoxCount={props.edit|props.readonlyFA ? fields.do.length : 3}
                  value={fields.do}
                  handleChange={handleChange}
                  name="do"
                  placeholder={t('enterCheckpoint')}
                  newFA={props.newFA}
                  readonlyFA={props.readonlyFA}
                />
                <AddActivity
                  title={t('auditTrailAct')}
                  className={props.edit ? "required" : ""}
                  defaultCheckBoxCount={props.edit|props.readonlyFA ? fields.act.length : 3}
                  value={fields.act}
                  handleChange={handleChange}
                  name="act"
                  placeholder={t('enterCheckpoint')}
                  newFA={props.newFA}
                  readonlyFA={props.readonlyFA}
                />
              </div>
            </div>
          </div>
          {submitable && (
            <div className="footer-wrapper">
              <Button
                color="primary"
                type="reset"
                className={classes.button}
                onClick={() => {
                  setFields({
                    plan: [],
                    act: [],
                    check: [],
                    do: [],
                  });
                  setSelectedRecords([]);
                  setSelectedSDGRecords([]);
                  setSelectedESGRecords([]);
                  setSelectedStandradRecords([]);
                  setSelectedMacroProcessRecords([]);
                  setSelectedEnterPriseRiskRecords([]);
                  setResetFormKey(resetFormKey + 1);
                }}
              >
                {t('reset')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.sendbutton}
                endIcon={<ArrowForwardIcon />}
                onClick={submitProposalsForm}
                disabled={props.newFA ? isSubmitted() : isMandatory()}
              >
                {t('submit')}
              </Button>
            </div>
          )}
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{ top: "0 !important" }}
          >
            <div className={classes.main}>
              <h2 id="spring-modal-title" className={classes.title}>
                {t('thanks')}
              </h2>
              <div className={classes.mainItem}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.proposeAgain}
                  onClick={() => {
                    setFields({
                      plan: [],
                      act: [],
                      check: [],
                      do: [],
                    });
                    setSelectedRecords([]);
                    setSelectedSDGRecords([]);
                    setSelectedESGRecords([]);
                    setSelectedStandradRecords([]);
                    setSelectedMacroProcessRecords([]);
                    setSelectedEnterPriseRiskRecords([]);
                    setResetFormKey(resetFormKey + 1);
                    handleClose();
                  }}
                >
                  {t('propose_agian')}
                </Button>
              </div>
              <div className={classes.mainItem}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.gotoLiabrary}
                  onClick={() => {
                    setFields({
                      plan: [],
                      act: [],
                      check: [],
                      do: [],
                    });
                    setSelectedRecords([]);
                    setSelectedSDGRecords([]);
                    setSelectedESGRecords([]);
                    setSelectedStandradRecords([]);
                    history.push("/focus-area");
                  }}
                >
                  {t('focus_area')}
                </Button>
              </div>
            </div>
          </Modal>
        </form>
      </div>
    </div>
  );
}
const mapStateToProps = ({ login }) => ({
  isAuthUser: login.isAuthUser || false,
  userInfo: login.userInfo,
  langInfo: login.langInfo
});
export default connect(
  mapStateToProps,
  null
)(withAITracking(reactPlugin, Proposals, "Proposal Page"));
