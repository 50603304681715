import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "./ProposalModal.css";
import closeIcon from "../assets/icons/Buttons-Close.png";
import { Proposals } from "../containers/Proposals";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "90%",
    backgroundColor: "#ccc",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    height: "90%",
    maxHeight: "87%",
    overflow: "auto",
    // padding: theme.spacing(2, 4, 3),
  },
}));

export default function FocusAreaModel(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const body = (
    <div style={modalStyle} className={"AdminProposalModal " + classes.paper}>
      <div className="ModalcloseIcon">
        <img
          className="propose-closeIcon"
          src={closeIcon}
          onClick={props.handleClose}
          alt="close"
        />
      </div>
      <Proposals
        activeRecordParams={props.activeRecordParams}
        edit={props.edit}
        newFA={props.newFA}
        readonlyFA={props.readonlyFA}
        submitable={props.submitable}
        userInfo={props.userInfo}
        onClose={props.handleClose}
        loadAdminData={props.loadAdminData}
        langInfo={props.langInfo}
        rowData={props.rowData}
      />
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ outline: 0, backgroundColor: "rgba(0, 0, 0, 0.8)" }}
      >
        {body}
      </Modal>
    </div>
  );
}
