export default class StandardJson {
  data = [];
  constructor(standardValue) {
    (standardValue.Standards.length > 0
      ? standardValue.Standards
      : []
    )?.forEach((element) => {
      this.data.push(new StandardLevelZeroNode(element));
    });
  }
}
class StandardLevelZeroNode {
  name = null;
  color = "#003591";
  category = "Standards";
  level = 0;
  key = 0;
  children = [];
  constructor(element) {
    this.name = element.name;
    (element.StandardName || []).forEach((standard) => {
      this.children.push(new Standard(standard));
    });
  }
}
class Standard {
  name = null;
  color = "#003591";
  category = "Standards";
  level = 1;
  children = [];
  key = null;
  description = null;
  constructor(standard) {
    this.key = standard.key;
    this.name = standard.name;
    this.description = standard.description;
    (standard.Mainprocesses || []).forEach((mainProcess) => {
      this.children.push(new MainProcess(mainProcess, standard.name));
    });
  }
}
class MainProcess {
  name = null;
  color = "#009fda";
  category = "Main Process";
  level = 2;
  children = [];
  key = null;
  constructor(mainProcess, standards) {
    this.name = mainProcess.name;
    this.key = mainProcess.key;
    (mainProcess.FocusAreas || []).forEach((focusArea) => {
      this.children.push(new FocusArea(focusArea, mainProcess.name, standards));
    });
  }
}
class FocusArea {
  name = null;
  EA_sector = {};
  sdg = {};
  esg = {};
  Enterprise_risks = null;
  averageRating = null;
  standard = {};
  color = null;
  category = "Focus Area";
  level = 3;
  Year_Of_Issue = null;
  main_process = null;
  FA_Audit_Trail_ACT = null;
  FA_Audit_Trail_CHECK = null;
  FA_Audit_Trail_DO = null;
  FA_Audit_Trail_PLAN = null;
  FA_Rationale = null;
  key = null;
  FA_ID = null;
  constructor(focusArea, mainProcess, standard) {
    this.key = focusArea.key;
    this.name = focusArea.name || "";
    this.color = focusArea.color || "#B42126";
    this.Year_Of_Issue = focusArea.Year_Of_Issue || "";
    this.averageRating = focusArea.averageRating || "";
    this.FA_Rationale = focusArea.FA_Rationale || "";
    this.EA_sector = focusArea.EA_sector ? focusArea.EA_sector : "";
    this.sdg = focusArea.sdg ? focusArea.sdg : "";
    this.esg = focusArea.esg ? focusArea.esg : "";
    this.Enterprise_risks = focusArea.Enterprise_risks;
    this.standard = focusArea.standard ? focusArea.standard : "";
    this.Main_process = focusArea.Main_process || mainProcess;
    this.FA_Audit_Trail_ACT = focusArea.FA_Audit_Trail_ACT;
    this.FA_Audit_Trail_CHECK = focusArea.FA_Audit_Trail_CHECK;
    this.FA_Audit_Trail_DO = focusArea.FA_Audit_Trail_DO;
    this.FA_Audit_Trail_PLAN = focusArea.FA_Audit_Trail_PLAN;
    this.FA_ID = focusArea.FA_ID;
  }
}
